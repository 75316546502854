import React, { useEffect, useState } from "react";
import "./ContactUs.css";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { ContactUs as cu } from "../../apis/ProfileAPI";
import Loader from "../../Components/Spinner/Loader";
import { useSelector } from "react-redux";
import "react-phone-number-input/style.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import { AddEnquiry, GetSignUpQ } from "../../apis/UserAPI";
import { GetCities, GetSalaryList } from "../../apis/MiscAPI";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-date-picker";
import { Helmet } from "react-helmet-async";

export default function ContactUs() {
  const { isAuthenticated, user, location } = useSelector(
    (state) => state.auth
  );
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showLoader, setShowLoader] = useState(false);
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [hideSubmit, setHideSubmit] = useState({
    submit: false,
    submitted: true,
  });

  const validationRegex = {
    firstName: /^[a-zA-Z' -]{2,30}$/,
    lastName: /^[a-zA-Z' -]{2,}$/,
    email: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?$/,
    age: /^(1[8-9]|[2-6][0-9]|70)$/,
  };

  const [maxDate, setMaxDate] = useState("");
  const [minDate, setMinDate] = useState("");

  const defaultContactData = {
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNo: "",
    dob: "",
    age: "",
    location: "",
    country: "",
    city: "",
    gender: "",
    qna: [],
    captcha: "",
    consent: "",
    tnc: "",
  };

  const [contactUsData, setContactusData] = useState(defaultContactData);
  const [phoneValidated, setPhoneValidated] = useState(true);
  const [validated, setValidated] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  const [dobValidated, setDobValidated] = useState(true);
  const [salaryList, setSalaryList] = useState([]);

  const calculateMaxDate = () => {
    const today = new Date();
    var mxDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    var mnDate = new Date(
      today.getFullYear() - 70,
      today.getMonth(),
      today.getDate()
    );
    setMaxDate(mxDate);
    // updateContactUsData("dob",mxDate);
    setMinDate(mnDate);
  };

  const ConvertDate = (value) => {
    return new Date(
      Date.UTC(
        value.getFullYear(),
        value.getMonth(),
        value.getDate(),
        value.getHours(),
        value.getMinutes(),
        value.getSeconds()
      )
    );
  };

  const updateContactUsData = (key, value) => {
    if (key === "dob") {
      value = ConvertDate(value);
      calculateAge(value);
      setDobValidated(true);
    } else if (key === "consent") {
      if (contactUsData.consent === "consented") value = "";
    } else if (key === "tnc") {
      if (contactUsData.tnc === "agreed") value = "";
    }
    setContactusData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (location != null) getCountry();
    calculateMaxDate();
    loadCaptchaEnginge(6, "#CDAC45", "white");
  }, [location]);

  useEffect(() => {
    if (country != "" && country != null && country != undefined) {
      getCities();
      getQn();
      getSalaries();
    }
  }, [country]);

  const getSalaries = async () => {
    var data = await GetSalaryList();
    if (countryCode !== "IN") {
      var salList = data.filter((x) => x.salary_type === "USD");
      setSalaryList(salList);
    } else {
      var salList = data.filter((x) => x.salary_type === "INR");
      setSalaryList(salList);
    }
  };

  const getCountry = async () => {
    updateContactUsData("location", location.city + ", " + location.country);
    updateContactUsData("country", location.country);
    setCountry(location.country);
    setCountryCode(location.countryCode);
  };

  const getCities = async () => {
    var result = await GetCities(countryCode);
    setCities(result);
  };

  const isObjectValid = (obj) => {
    let contactUsObj = obj;
    console.log(obj);
    for (const key in contactUsObj) {
      if (contactUsObj.hasOwnProperty(key)) {
        switch (key) {
          case "qna":
            // Validate array of Q&A pairs
            const qna = contactUsObj[key];
            //console.log(qna);
            for (const qa of qna) {
              if (qa.question !== "Social Media link" && !qa.answer) {
                return false; // Return false if any answer is missing
              }
            }
            break;
          case "firstName":
            if (!validationRegex.firstName.test(contactUsObj[key]))
              return false;
            break;
          case "lastName":
            if (!validationRegex.lastName.test(contactUsObj[key])) return false;
            break;
          case "emailId":
            // console.log(contactUsObj[key]);
            if (!validationRegex.email.test(contactUsObj[key])) return false;
            break;
          case "phoneNo":
            if (!isValidPhoneNumber(contactUsObj[key], countryCode)) {
              setPhoneValidated(false);
              return false;
            } else setPhoneValidated(true);
            break;
          case "dob":
            // Validate date of birth
            if (!contactUsObj[key]) {
              setDobValidated(false); // Set date of birth validation status
              return false; // Return false if date of birth is missing
            } else {
              setDobValidated(true); // Set date of birth validation status
            }
            break;
          case "age":
            if (!validationRegex.age.test(contactUsObj[key])) return false;
            break;
          default:
            if (contactUsObj[key] === "") {
              updateContactUsData([key], contactUsObj[key]);
              return false; // Invalid value found
            }
            break;
        }
      }
    }
    return true; // All properties have valid values
  };

  const handleContactUs = async (e) => {
    e.preventDefault();
    let userCaptcha = document.getElementById("captcha").value;
    setInitialLoad(false);
    if (validateCaptcha(userCaptcha) === true) {
      var valid = isObjectValid(contactUsData);
      if (valid) {
        var qna = [];
        contactUsData.qna.forEach((item) => {
          qna.push({ Question: item.question, Answer: item.answer });
        });

        let data = {
          membershipId: 0,
          firstName: contactUsData.firstName,
          lastName: contactUsData.lastName,
          dob: contactUsData.dob,
          age: contactUsData.age + "",
          gender: contactUsData.gender,
          userEmail: contactUsData.emailId,
          phoneNo: contactUsData.phoneNo,
          location: contactUsData.location,
          country: contactUsData.country,
          city: contactUsData.city,
          qnA: qna,
        };

        setShowLoader(true);
        await AddEnquiry(data);
        setShowLoader(false);
        setHideSubmit({ submit: true, submitted: false });
        setContactusData(defaultContactData);
        setInitialLoad(true);
        updateContactUsData(
          "location",
          location.city + ", " + location.countryl
        );
        setSelectedCity([]);
        setTimeout(() => {setHideSubmit({submit: false, submitted: true})},3000);

      }
    } else {
      updateContactUsData("captcha", "");
    }
    setValidated(false);
  };

  const handleCityChange = (selectedOptions) => {
    if (selectedOptions.length > 0) {
      const selectedCityName = selectedOptions[0].name;
      setSelectedCity(selectedOptions);
      updateContactUsData("city", selectedCityName);
    } else {
      setSelectedCity([]); // Clear selection if no option is selected
      updateContactUsData("city", ""); // Clear city in contactUsData
    }
  };

  const handlePhoneValidation = () => {
    var vp = isValidPhoneNumber(contactUsData.phoneNo, countryCode);
    if (!vp) setPhoneValidated(false);
    else setPhoneValidated(true);
  };

  const getQn = async () => {
    var result = await GetSignUpQ();
    result.forEach((element) => {
      updateQnA(element, null, "initial");
    });
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();

    // Adjust for birth month and day
    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    updateContactUsData("age", age);
  };

  const updateQnA = (qn, ans, render) => {
    //console.log(qn,ans, render, contactUsData.qna);
    var updatedUserQnA = contactUsData.qna;
    const existingQIndex = updatedUserQnA.findIndex((q) => q.id === qn.id);

    if (existingQIndex !== -1) {
      // Update existing question
      const isInitialRender = render === "initial";
      qn.answer = isInitialRender ? qn.answer : ans;
      updatedUserQnA[existingQIndex] = qn;
    } else {
      // Add new question
      updatedUserQnA.push(qn);
    }
    updateContactUsData("qna", updatedUserQnA);
  };

  return (
    <>
    <Helmet>
      <title>Contact Us | Two 2 Tango</title>
      <meta name="description" content="At Two 2 Tango, we believe in the power of connection. Whether you have a question, need assistance, we’d love to hear from you! Call: London: +44 7932303037, USA: +1 (910) 644-0343" />
    </Helmet>
      <section className="my-membership-section contact-us">
        <Container>
          <Row>
            <Col>
              <div className="contact-info-head">
                <h2
                  className="healine"
                  data-aos="zoom-in"
                  data-aos-duration="500"
                >
                  <span>We would love to hear from you</span>
                </h2>
                {/* <h4>Email us or reach us offline at:</h4>  */}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12}>
              <div className="contact-info">
                <div className="contact-info-row">
                  <div className="contact-info-col">
                    <div className="contact-info-col-inner">
                      <span>Email:</span>{" "}
                      <a href="mailto:contactus@two2tango.com">
                        contactus@two2tango.com
                      </a>
                    </div>
                  </div>
                  <div className="contact-info-col">
                    <div className="contact-info-col-inner">
                      <span>London:</span>{" "}
                      <a href="tel:+447932303037">+44 7932303037</a>
                    </div>
                    {/* <div className="contact-info-col-inner">
                      <span>India:</span>{" "}
                      <a href="tel:+919821118813">+91 9821118813</a>
                    </div> */}
                    <div className="contact-info-col-inner">
                      <span>USA:</span>{" "}
                      <a href="tel:+19106440343">+1 910 644-0343</a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={6} md={12}>
              <div className="my-membership-section-inner referrals-inner contact-us-inner">
                <Form
                  noValidate
                  onSubmit={handleContactUs}
                  validated={validated}
                  autoComplete="off"
                >
                  <Form.Group
                    as={Row}
                    className="my-membership-inout-row"
                    controlId="FName"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <Form.Label column sm="12">
                      First Name:
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        value={contactUsData.firstName}
                        onChange={(e) =>
                          updateContactUsData("firstName", e.target.value)
                        }
                        required
                        isInvalid={
                          !initialLoad &&
                          !validationRegex.firstName.test(
                            contactUsData.firstName
                          )
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid name (minimum 2 characters,
                        alphabetic characters only).
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="my-membership-inout-row"
                    controlId="LName"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <Form.Label column sm="12">
                      Last Name:
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        value={contactUsData.lastName}
                        onChange={(e) =>
                          updateContactUsData("lastName", e.target.value)
                        }
                        required
                        isInvalid={
                          !initialLoad &&
                          !validationRegex.lastName.test(contactUsData.lastName)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid name (minimum 2 characters,
                        alphabetic characters only).
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="my-membership-inout-row"
                    controlId="emailid"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <Form.Label column sm="12">
                      Email:
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        value={contactUsData.emailId}
                        onChange={(e) =>
                          updateContactUsData("emailId", e.target.value)
                        }
                        required
                        isInvalid={
                          !initialLoad &&
                          !validationRegex.email.test(contactUsData.emailId)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide a valid email
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="my-membership-inout-row"
                    controlId="phoneno"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <Form.Label column sm="12">
                      Phone No.:
                    </Form.Label>
                    <Col sm="12">
                      <PhoneInput
                        international
                        countryCallingCodeEditable={false}
                        defaultCountry={countryCode}
                        placeholder="Enter phone number"
                        value={contactUsData.phoneNo}
                        onChange={(e) => updateContactUsData("phoneNo", e)}
                        rules={{ required: true }}
                        onBlur={handlePhoneValidation}
                      />
                      {!initialLoad && !phoneValidated && (
                        <Form.Text className="text-danger">
                          Please provide valid phone number
                        </Form.Text>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="my-membership-inout-row"
                    controlId="dob"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <Form.Label column sm="12">
                      Date of Birth
                    </Form.Label>
                    <Col sm="12">
                      <DatePicker
                        value={contactUsData.dob}
                        onChange={(e) => updateContactUsData("dob", e)}
                        dayPlaceholder="dd"
                        monthPlaceholder="mm"
                        yearPlaceholder="yyyy"
                        maxDate={maxDate}
                        minDate={minDate}
                        required
                        onInvalidChange={() => setDobValidated(false)}
                      />
                      {!initialLoad && !dobValidated && (
                        <Form.Text className="text-danger">
                          Please provide valid dob
                        </Form.Text>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="my-membership-inout-row"
                    controlId="age"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <Form.Label column sm="12">
                      Age:
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        type="number"
                        placeholder="Age"
                        value={contactUsData.age}
                        onChange={(e) =>
                          updateContactUsData("age", e.target.value)
                        }
                        required
                        isInvalid={
                          !initialLoad &&
                          !validationRegex.age.test(contactUsData.age)
                        }
                        min={18}
                        max={70}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide valid age
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>

                  <Form.Group
                    as={Row}
                    className="my-membership-inout-row"
                    controlId="location"
                    data-aos="fade-up"
                    data-aos-duration="500"
                  >
                    <Form.Label column sm="12">
                      Location:
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        type="tel"
                        placeholder="Location"
                        value={contactUsData.location}
                        onChange={(e) =>
                          updateContactUsData("location", e.target.value)
                        }
                        required
                        isInvalid={
                          !initialLoad && contactUsData.location === ""
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Please provide location
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="my-membership-inout-row"
                    controlId="city" 
                  >
                    <Form.Label column sm="12">
                      City:
                    </Form.Label>
                    <Typeahead
                      id="consultation_city"
                      labelKey="name"
                      options={cities}
                      placeholder="city living in"
                      selected={selectedCity}
                      onChange={handleCityChange}
                      isInvalid={
                        !initialLoad &&
                        (!selectedCity || selectedCity.length === 0)
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Please provide name of the city you live in
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="my-membership-inout-row"
                    controlId="gender"
                  >
                    <Form.Label>Gender:</Form.Label>
                    <div className="radios-btns-gender">
                      <Form.Check
                        inline
                        label="Male"
                        name="Gender"
                        type="radio"
                        id="Male"
                        value="Male"
                        checked={contactUsData.gender === "Male"}
                        onChange={(e) =>
                          updateContactUsData("gender", e.target.value)
                        }
                        required
                      />
                      <Form.Check inline name="Gender" id="Female">
                        <Form.Check.Input
                          type="radio"
                          value="Female"
                          checked={contactUsData.gender === "Female"}
                          onChange={(e) =>
                            updateContactUsData("gender", e.target.value)
                          }
                          isInvalid={!initialLoad && contactUsData.gender == ""}
                        />
                        <Form.Check.Label>Female</Form.Check.Label>
                        <Form.Control.Feedback type="invalid">
                          Please fill this field
                        </Form.Control.Feedback>
                      </Form.Check>
                    </div>
                  </Form.Group>

                  {contactUsData.qna.length > 0 &&
                    contactUsData.qna.map((ques) =>
                      ques.field_type === "radio_text" ? (
                        <Form.Group
                          as={Col}
                          md="12"
                          className="my-membership-inout-row"
                          controlId={ques.id}
                          key={ques.id}
                        >
                          <Form.Label>{ques.question}</Form.Label>
                          <div className="radios-btns-gender">
                            {ques.option !== null &&
                              ques.option.split("~").map((opt, indx) => (
                                <div key={`${ques.id}${indx}`}>
                                  {ques.option.split("~").length - 1 ===
                                  indx ? (
                                    <Form.Check
                                      inline
                                      name={`qo${indx}${ques.id}`}
                                      id={`${indx}${ques.id}`}
                                    >
                                      <Form.Check.Input
                                        type="radio"
                                        value={opt}
                                        checked={
                                          (ques.answer != null &&
                                            ques.answer == opt) ||
                                          (ques.answer != null &&
                                            ques.answer.indexOf("^") !== -1 &&
                                            ques.answer.indexOf(opt.trim()) !==
                                              -1)
                                        }
                                        onChange={(e) =>
                                          updateQnA(ques, e.target.value, null)
                                        }
                                        isInvalid={
                                          !initialLoad && ques.answer === ""
                                        }
                                      />
                                      <Form.Check.Label>{opt}</Form.Check.Label>
                                      <Form.Control.Feedback type="invalid">
                                        Please fill this field
                                      </Form.Control.Feedback>
                                    </Form.Check>
                                  ) : (
                                    <Form.Check
                                      inline
                                      name={`qo${indx}${ques.id}`}
                                      id={`${indx}${ques.id}`}
                                    >
                                      <Form.Check.Input
                                        type="radio"
                                        value={opt}
                                        checked={
                                          (ques.answer != null &&
                                            ques.answer == opt) ||
                                          (ques.answer != null &&
                                            ques.answer.indexOf("^") !== -1 &&
                                            ques.answer.indexOf(opt.trim()) !==
                                              -1)
                                        }
                                        onChange={(e) =>
                                          updateQnA(ques, e.target.value, null)
                                        }
                                      />
                                      <Form.Check.Label>{opt}</Form.Check.Label>
                                    </Form.Check>
                                  )}
                                  {opt.trim() === "Other" &&
                                    ques.answer != null &&
                                    ques.answer.indexOf("Other") !== -1 && (
                                      <>
                                        <Form.Control
                                          key={`QT_${indx}_${ques.id}`}
                                          type="text"
                                          placeholder={ques.question}
                                          value={
                                            ques.answer == null
                                              ? ""
                                              : ques.answer.split("^")[1]
                                          }
                                          onChange={(e) =>
                                            updateQnA(
                                              ques,
                                              "Other^" + e.target.value,
                                              null
                                            )
                                          }
                                        />
                                      </>
                                    )}
                                </div>
                              ))}
                          </div>
                        </Form.Group>
                      ) : ques.field_type === "radio" ? (
                        <Form.Group
                          as={Col}
                          md="12"
                          className="my-membership-inout-row"
                          controlId={ques.id}
                          key={ques.id}
                        >
                          <Form.Label>{ques.question}</Form.Label>
                          <div className="radios-btns-gender">
                            {ques.option !== null &&
                              ques.option.split("~").map((o, i) => (
                                <div key={`${ques.id}${i}`}>
                                  {ques.option.split("~").length - 1 === i ? (
                                    <Form.Check
                                      inline
                                      name={`qo${i}${ques.id}`}
                                      id={`${i}${ques.id}`}
                                    >
                                      <Form.Check.Input
                                        type="radio"
                                        value={o}
                                        checked={
                                          ques.answer != null &&
                                          ques.answer == o
                                        }
                                        onChange={(e) =>
                                          updateQnA(ques, e.target.value)
                                        }
                                        isInvalid={
                                          !initialLoad && ques.answer === ""
                                        }
                                      />
                                      <Form.Check.Label>{o}</Form.Check.Label>
                                      <Form.Control.Feedback type="invalid">
                                        Please fill this field
                                      </Form.Control.Feedback>
                                    </Form.Check>
                                  ) : (
                                    <Form.Check
                                      inline
                                      name={`qo${i}${ques.id}`}
                                      id={`${i}${ques.id}`}
                                    >
                                      <Form.Check.Input
                                        type="radio"
                                        value={o}
                                        checked={
                                          ques.answer != null &&
                                          ques.answer == o
                                        }
                                        onChange={(e) =>
                                          updateQnA(ques, e.target.value)
                                        }
                                      />
                                      <Form.Check.Label>{o}</Form.Check.Label>
                                    </Form.Check>
                                  )}
                                </div>
                              ))}
                          </div>
                        </Form.Group>
                      ) : ques.field_type === "text" ? (
                        <Form.Group
                          as={Col}
                          className="my-membership-inout-row"
                          controlId={ques.id}
                          data-aos="fade-up"
                          data-aos-duration="1500"
                        >
                          <Form.Label column>{ques.question}</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={ques.question}
                            value={ques.answer ?? ""}
                            onChange={(e) =>
                              updateQnA(ques, e.target.value, null)
                            }
                          />
                        </Form.Group>
                      ) : ques.field_type === "select_2" ? (
                        <Form.Group
                          as={Col}
                          md="12"
                          className="my-membership-inout-row"
                          controlId={ques.id}
                          key={ques.id}
                        >
                          <Form.Label column>{ques.question}</Form.Label>
                          <Form.Select
                            className="signup-select"
                            aria-label={ques.question}
                            size="sm"
                            value={ques.answer == null ? "" : ques.answer}
                            onChange={(e) =>
                              updateQnA(ques, e.target.value, null)
                            }
                            isInvalid={!initialLoad && ques.answer === ""}
                          >
                            <option key="salary">Annual Income</option>
                            {salaryList.map((sal, i) => (
                              <option
                                value={sal.salary_slab}
                                key={`sal_opt_${i}`}
                              >
                                {sal.salary_slab}
                              </option>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please fill this field
                          </Form.Control.Feedback>
                        </Form.Group>
                      ) : (
                        ""
                      )
                    )}
                  <Form.Group
                    as={Col}
                    md="12"
                    className="radios-btns-gender tnc-checks"
                    controlId="consent-control"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <Form.Check inline name="consent" id="consent">
                      <Form.Check.Input
                        type="checkbox"
                        value="consented"
                        checked={contactUsData.consent === "consented"}
                        onChange={(e) =>
                          updateContactUsData("consent", e.target.value)
                        }
                        isInvalid={!initialLoad && contactUsData.consent === ""}
                        required
                      />
                      <Form.Check.Label>
                        I agree that information I have provided here is true
                        and accurate
                      </Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        Please fill this field
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                  <Form.Group
                    as={Col}
                    md="12"
                    className="radios-btns-gender tnc-checks"
                    controlId="tnc"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <Form.Check inline name="tnc" id="tnc">
                      <Form.Check.Input
                        type="checkbox"
                        value="agreed"
                        checked={contactUsData.tnc === "agreed"}
                        onChange={(e) =>
                          updateContactUsData("tnc", e.target.value)
                        }
                        isInvalid={!initialLoad && contactUsData.tnc === ""}
                        required
                      />
                      <Form.Check.Label>
                        I am in full agreement with{" "}
                        <a
                          href="https://www.two2tango.com/terms-conditions"
                          target="_blank"
                          rel="noreferrer"
                        >
                          terms & conditions
                        </a>{" "}
                        and{" "}
                        <a
                          href="https://www.two2tango.com/privacy-policy"
                          target="_blank"
                          rel="noreferrer"
                        >
                          privacy policy
                        </a>
                      </Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        Please fill this field
                      </Form.Control.Feedback>
                    </Form.Check>
                  </Form.Group>
                  <Form.Group
                    as={Row}
                    className="my-membership-inout-row"
                    controlId="captcha"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    <Form.Label column sm="12">
                      <LoadCanvasTemplateNoReload />
                    </Form.Label>
                    <Col sm="12">
                      <Form.Control
                        type="text"
                        placeholder="captcha"
                        value={contactUsData.captcha}
                        isInvalid={!initialLoad && contactUsData.captcha === ""}
                        onChange={(e) =>
                          updateContactUsData("captcha", e.target.value)
                        }
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        Invalid Captcha
                      </Form.Control.Feedback>
                    </Col>
                  </Form.Group>
                  <div></div>
                  <div
                    className="membership-action-btns"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <Button type="submit" hidden={hideSubmit.submit}>
                      Submit <Loader visible={showLoader} />
                    </Button>
                    <Button hidden={hideSubmit.submitted}>Submitted</Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Modal
        className="upgrade-membership-modal"
        show={show}
        onHide={handleClose}
        size="md"
        centered
      >
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body>
          Thank you for contacting us. We will get back to you shortly
        </Modal.Body>
      </Modal>
    </>
  );
}
