import React, { useEffect } from "react";
import "./Home.css";
import { Col, Container, Row } from "react-bootstrap";
import HomeBnr from "../../images/home-bnr-vector1.png";
import BnrVector from "../../images/yellow-flowers-vector.png";
import Curated3 from "../../images/fold-2-bg3.jpg";
import BelongHereImg from "../../images/home-fold-3-left-img.jpg";
import HomeBoatImg from "../../images/boat-vector.png";
import { useParallax } from "react-scroll-parallax";
import BookConsultation from "../../Components/BookConsultation/BookConsultation";
import { Helmet } from "react-helmet-async";

export default function HomePage() {
  
  const parallax0 = useParallax({
    translateX: [0, 0, "easeOutQuint"],
    translateY: [20, -100, "easeInQuint"],
  });
  const parallax = useParallax({
    translateX: [-50, 13, "easeOutQuint"],
    translateY: [0, 0, "easeInQuint"],
  });
  const parallax2 = useParallax({
    translateX: [0, 0, "easeOutQuint"],
    translateY: [0, 0, "easeInQuint"],
  });
  const parallax3 = useParallax({
    translateX: [-80, 0, "easeOutQuint"],
    translateY: [0, 0, "easeInQuint"],
  });
  // const parallaxRotate = useParallax({
  //   rotate: [0, 0],
  // });

  return (
    <>
    <Helmet>
      <title>A Global Community for Singles Seeking Authentic Connections</title>
      <meta name="description" content="Two 2 Tango is more than a dating platform; it's a community that values real connections and love. Sign up to experience a journey of relationships that heal, inspire, and embrace you for who you are." />
    </Helmet>
      <section className="curated-connections">
        <Container>
          <Row>
            <Col>
              <div className="section-head" data-aos="zoom-in-up">
                {/* <h2>Curated Connections Beyond Borders.</h2> */} 
                {/* <h2>Authenticity. Intent. Quality. Curation. Love.</h2> */}
                <h2>Diverse. Empowering. Inspiring. Committed.</h2>
                <h4>
                  <span></span>
                </h4> 
              </div>
              <br></br>
              <h3 class="mt-4">Welcome to Two 2 Tango - A global community for singles</h3>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col lg={4} md={6}>
              <div
                className="curated-col curated1"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <p>Humans are not wired to live in isolation; we thrive best in environments where we feel connected and understood. At different stages, we seek different things - whether it’s friendships, love, or a sense of belonging.</p>  
              </div>
            </Col>
            <Col lg={5} md={6}>
              <div
                className="curated-col curated2"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                {/* <p>Forget awkward swiping and forced perfection. </p>
                <br />
                <p>
                  Here laughter, wisdom, and a dash of sass are not just
                  welcome, they're celebrated. So dust off your dancing shoes
                  and prepare to tango into meaningful connections that stand
                  the test of time.
                </p> */}
                <p>We invite you to join us on a journey that values relationships, whether platonic or romantic. We aim to build a community that inspires, heals, challenges, supports, and—most importantly—embraces us for who we are.</p>
              </div>
            </Col>
            <Col lg={3}>
              <div
                className="curated-col curated3"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <p>Let the magic begin.</p>
                {/* <img
                  src={Curated3}
                  alt="Tow To Tango"
                  ref={parallaxRotate.ref}
                /> */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="home-bnr">
        <Container>
          <Row>
            <Col
              lg={6}
              md={12}
              className="home-bnr-left-col"
              ref={parallax0.ref}
            >
              <h1 data-aos="fade-up"> 
              “Love is the whole thing.<br />
              We are only pieces.”
              <span>- Rumi</span>
              </h1>
              {/* <h4 data-aos="fade-up">
                <span>Craft Your Love Story.</span>
              </h4> */}
              <p data-aos="fade-up">
              Enter the fabulous world of Two 2 Tango to orchestrate your love story across some of the world's most vibrant cities: New York, San Francisco, Dubai, London, Mumbai, New Delhi, Singapore, and Hong Kong.</p>
              <p data-aos="fade-up">Dust off your dancing shoes and step into a world of romance. </p>
            </Col>
            <Col
              lg={6}
              md={12}
              className="home-bnr-right-col"
              data-aos="fade-up"
            >
              <img src={HomeBnr} alt="Tow To Tango Banner" ref={parallax.ref} />
            </Col>
          </Row>
        </Container>
        <div className="banner-vector" ref={parallax2.ref}>
          <img src={BnrVector} alt="Tow To Tango Banner" />
        </div>
        <BookConsultation />
      </section>
      
      <section className="belong-here-section">
        <Container>
          <Row>
            <Col lg={6} md={6}>
              <div
                className="belong-here-section-left"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                <img src={BelongHereImg} alt="Tow To Tango" />
              </div>
            </Col>
            <Col lg={6} md={6}>
              <div className="belong-here-section-right">
                <div className="belong-here-section-right-inner">
                  <div
                    className="belong-here-section-right-inner-items"
                    data-aos="fade-left"
                    data-aos-duration="2000"
                    data-aos-easing="ease-in-sine"
                  > 
                    <p>Relationships are a beautiful mix of depth, growth, and the occasional chaos. It's not about being with someone who shares the same interests, but more importantly, the one who shares your commitment and desire to create a shared space—the one who makes you feel safe to be vulnerable, to dream bigger, and to laugh louder at the ridiculousness of life.</p>
                    <p class="mt-3">Because love, like life, is about the messy moments as much as the magical ones.</p> 
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="home-boat" ref={parallax3.ref}>
          <img src={HomeBoatImg} alt="Tow To Tango" />
        </div>
      </section>
    </>
  );
}
