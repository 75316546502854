import { Form } from "react-bootstrap";

export function RenderRadio(props) {
  return (
    <>
      {props.isReadOnly ? (
        <Form.Group
          key={"Q_" + props.item.question_id}
          className="my-profile-info-form-row full-width-input"
          controlId={"Q_" + props.item.question_id}
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column key={"QL_" + props.item.question_id}>
            {props.item.question}
          </Form.Label>
          <div className="radios-btns-gender">
            {props.item.option != null &&
              props.item.option.split("~").map((o, i) => (
                <div key={`QR_${i}_${props.item.question_id}`}>
                  {props.item.option.split("~").length - 1 === i ? (
                    <Form.Check
                      inline
                      name={`qo${i}${props.item.question_id}`}
                      id={`${i}${props.item.question_id}`}
                    >
                      <Form.Check.Input
                        type="radio"
                        value={o}
                        checked={
                          props.item.answer != null &&
                          props.item.answer.localeCompare(o, 'en', { sensitivity: 'base' }) === 0
                        }
                        readOnly
                      />
                      <Form.Check.Label>{o}</Form.Check.Label>
                    </Form.Check>
                  ) : (
                    <Form.Check
                      inline
                      name={`qo${i}${props.item.question_id}`}
                      id={`${i}${props.item.question_id}`}
                    >
                      <Form.Check.Input
                        type="radio"
                        value={o}
                        checked={
                          props.item.answer != null &&
                          props.item.answer.localeCompare(o, 'en', { sensitivity: 'base' }) === 0
                        }
                        readOnly
                      />
                      <Form.Check.Label>{o}</Form.Check.Label>
                    </Form.Check>
                  )}
                </div>
              ))}
          </div>
        </Form.Group>
      ) : (
        <Form.Group
          key={"Q_" + props.item.question_id}
          className="my-profile-info-form-row full-width-input"
          controlId={"Q_" + props.item.question_id}
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Form.Label column key={"QL_" + props.item.question_id}>
            {props.item.question}
          </Form.Label>
          <div className="radios-btns-gender">
            {props.item.option != null &&
              props.item.option.split("~").map((o, i) => (
                <div key={`QR_${i}_${props.item.question_id}`}>
                  {props.item.option.split("~").length - 1 === i ? (
                    <Form.Check
                      inline
                      name={`qo${i}${props.item.question_id}`}
                      id={`${i}${props.item.question_id}`}
                    >
                      <Form.Check.Input
                        type="radio"
                        value={o}
                        checked={
                          props.item.answer.value != null &&
                          props.item.answer.value.localeCompare(o, 'en', { sensitivity: 'base' }) === 0
                        }
                        onChange={(e) =>
                          props.updateQnA(props.item, e.target.value)
                        }
                        isInvalid={
                          props.item.answer.isInvalid &&
                          props.item.answer.mode !== "save"
                        }
                      />
                      <Form.Check.Label>{o}</Form.Check.Label>
                      <Form.Control.Feedback type="invalid">
                        Please fill this field
                      </Form.Control.Feedback>
                    </Form.Check>
                  ) : (
                    <Form.Check
                      inline
                      name={`qo${i}${props.item.question_id}`}
                      id={`${i}${props.item.question_id}`}
                    >
                      <Form.Check.Input
                        type="radio"
                        value={o}
                        checked={
                          props.item.answer.value != null &&
                          props.item.answer.value.localeCompare(o, 'en', { sensitivity: 'base' }) === 0
                        }
                        onChange={(e) =>
                          props.updateQnA(props.item, e.target.value)
                        }
                      />
                      <Form.Check.Label>{o}</Form.Check.Label>
                    </Form.Check>
                  )}
                </div>
              ))}
          </div>
        </Form.Group>
      )}
    </>
  );
}
