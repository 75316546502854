import React, { useEffect, useState, useRef } from "react";
import "./Header.css";
import {
  Button,
  Container,
  Dropdown,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import TTTlogo from "../../images/two2tangoo-logo2.png";
import HearIcon from "../../images/heart-icon.png";
import BellIcon from "../../images/bell-icon.png";
import ChatIcon from "../../images/chat-icon.png";
// import FbIcon from "../../images/fb-icon.png";
// import InIcon from "../../images/in-icon.png";
// import InstaIcon from "../../images/insta-icon.png";

import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../slices/authSlice";
import { GetBucketUrl } from "../../utils/Utility";

export default function Header() {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [userName, setUserName] = useState("");
  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (isAuthenticated) {
      setLoggedIn(true);
      setUserName(user.userName);
    }
  }, [isAuthenticated]);

  const menuCollapse = useRef(null);
  const handleClick = () => {
    menuCollapse.current.click();
  };

  return (
    <>
      <header className="ttt-header">
        <Navbar collapseOnSelect expand="lg" fixed="top">
          <Container fluid>
            <Navbar.Brand href="/">
              <img src={TTTlogo} alt="TTT Logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="ttt-navbar-nav" ref={menuCollapse} />
            <Navbar.Collapse id="ttt-navbar-nav">
              <Nav className="m-auto">
                <NavLink
                  className="nav-link"
                  href="/"
                  to="/"
                  onClick={handleClick}
                >
                  Home
                </NavLink>
                <NavLink
                  className="nav-link"
                  href="/why-us"
                  to="/why-us"
                  onClick={handleClick}
                >
                  Why Us
                </NavLink>
                <NavLink
                  className="nav-link"
                  href="/about"
                  to="/about"
                  onClick={handleClick}
                >
                  Our Community
                </NavLink>

                <NavLink
                  className="nav-link"
                  href="/membership"
                  to="/membership"
                >
                  <span onClick={handleClick}>Find a Match</span>
                </NavLink>

                <NavLink
                  className="nav-link"
                  href="/faqs"
                  to="/faqs"
                  onClick={handleClick}
                >
                  FAQS
                </NavLink>

                {!loggedIn && (
                  <>
                    <NavLink
                      className="nav-link"
                      href="/contact"
                      to="/contact"
                      onClick={handleClick}
                    >
                      Contact Us
                    </NavLink>
                    <NavLink
                      className="nav-link"
                      href="/signin"
                      to="/signin"
                      onClick={handleClick}
                    >
                      Sign in
                    </NavLink>
                  </>
                )}
                {loggedIn && (
                  <>
                    <NavDropdown
                      className="profile-dropdown"
                      title="Profile"
                      id="ProfileDropdown"
                    >
                      <NavLink
                        className="dropdown-item"
                        to="/myprofile"
                        onClick={() => navigate("/myprofile")}
                      >
                        <div className="profile-name" onClick={handleClick}>
                          {userName}
                        </div>
                      </NavLink>
                      <NavLink
                        className="dropdown-item"
                        href="/mymembership"
                        to="/mymembership"
                        onClick={handleClick}
                      >
                        My Membership
                      </NavLink>
                      <NavLink
                        className="dropdown-item"
                        href="/changepassword"
                        to="/changepassword"
                        onClick={handleClick}
                      >
                        Change Password
                      </NavLink>
                      <NavLink className="dropdown-item" onClick={handleLogout}>
                        Sign Out
                      </NavLink>
                    </NavDropdown>
                  </>
                )}
              </Nav>
            </Navbar.Collapse>
            <div className="profile-header-item">
              <div className="profile-header-item-inner">
                {loggedIn && (
                  <div className="profile-header-item-btn">
                    <Button
                      title="My Matches"
                      onClick={() => navigate("/mymatches")}
                    >
                      <img
                        className="myMatchesTcon"
                        src={HearIcon}
                        alt="TTT Icon"
                      />
                      <span className="myMatchesText">My Matches</span>
                    </Button>
                  </div>
                )}
                <div
                  className="profile-header-item-btn"
                  style={{ display: "none" }}
                >
                  <Button>
                    <img src={BellIcon} alt="TTT Icon" />{" "}
                    <span className="notification-badge">9</span>
                  </Button>
                </div>
                <div
                  className="profile-header-item-btn"
                  style={{ display: "none" }}
                >
                  <Button>
                    <img src={ChatIcon} alt="TTT Icon" />{" "}
                    <span className="notification-badge">10</span>
                  </Button>
                </div>
                {loggedIn && (
                  <div className="profile-header-item-btn">
                    <Button
                      className="profile-pic"
                      onClick={() => navigate("/myprofile")}
                    >
                      <img
                        src={
                          user != null && user.profileImg !== ""
                            ? `${GetBucketUrl()}${user.userId}/${
                                user.profileImg
                              }?modifed=${user.modifiedDate}`
                            : user.userGender === "male"
                            ? `${GetBucketUrl()}default_man.png`
                            : `${GetBucketUrl()}default_woman.png`
                        }
                        alt="TTT Icon"
                      />{" "}
                      <div className="profile-name">{userName}</div>
                    </Button>
                  </div>
                )}
              </div>
            </div>
            {loggedIn && (
              <Dropdown className="hamburger-menu">
                <Dropdown.Toggle id="hamburger-menu">
                  <span className="hamburger-menu-lines"></span>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {loggedIn && (
                    <>
                      {" "}
                      {/*  <NavLink
                      className="dropdown-item"
                      href="/relationshipmanager"
                      to="/relationshipmanager"
                    >
                      Relationship Manager
                    </NavLink> */}
                      <NavLink
                        className="dropdown-item"
                        href="/mymembership"
                        to="/mymembership"
                        onClick={handleClick}
                      >
                        My Membership
                      </NavLink>
                    </>
                  )}

                  {/* <NavLink className="dropdown-item" href="/Media" to="/Media">
                  Media
                </NavLink> */}
                  {/* <NavLink
                  className="dropdown-item"
                  href="/Newsletter"
                  to="/Newsletter"
                >
                  Newsletter
                </NavLink> */}
                  {/* {loggedIn && (
                  <>
                    <NavLink
                      className="dropdown-item"
                      href="/referrals"
                      to="/referrals"
                    >
                      Referrals
                    </NavLink>
                  </>
                )} */}
                  {/* {!loggedIn && (
                  <NavLink
                    className="dropdown-item"
                    href="/ContactUs"
                    to="/ContactUs"
                  >
                    Contact Us
                  </NavLink>
                )} */}
                  {/* <NavLink
                  className="dropdown-item"
                  href="/followus"
                  to="/followus"
                >
                  Follow Us
                </NavLink> */}
                  {loggedIn && (
                    <>
                      <NavLink
                        className="dropdown-item"
                        href="/changepassword"
                        to="/changepassword"
                        onClick={handleClick}
                      >
                        Change Password
                      </NavLink>
                      <NavLink className="dropdown-item" onClick={handleLogout}>
                        Sign Out
                      </NavLink>
                    </>
                  )}
                  {/* <Nav className="footer-social footer-social-header">
                  <Nav.Item>
                    <Nav.Link href="#">
                      <img src={FbIcon} alt="TTT FB" />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="#">
                      <img src={InIcon} alt="TTT IN" />
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="#">
                      <img src={InstaIcon} alt="TTT Insta" />
                    </Nav.Link>
                  </Nav.Item>
                </Nav> */}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </Container>
        </Navbar>
      </header>
    </>
  );
}
