import React from "react";
import "./AboutUs.css";
import { Col, Container, ListGroup, Row } from "react-bootstrap";
import LadyDancing from "../../images/lady-dancing.png";
import LetsShowImg1 from "../../images/lets-show-img1.jpg";
import LetsShowImg2 from "../../images/lets-show-img2.jpg";
import LetsShowImg3 from "../../images/lets-show-img3.jpg";
import OldMusicSystem from "../../images/about-btm-bg.png";
import BookConsultation from "../../Components/BookConsultation/BookConsultation";
import { useParallax } from "react-scroll-parallax";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

export default function AboutUs() {
  const parallax0 = useParallax({
    translateX: [-100, 10, "easeOutQuint"],
    translateY: [0, 0, "easeInQuint"],
  });
  return (
    <>
      <Helmet>
        <title>
          About Us - Two 2 Tango | A Global Community for Meaningful Connections
        </title>
        <meta
          name="description"
          content="Discover Two 2 Tango, a community for singles seeking authentic connections, whether for friendship, adventure, or love. Join us in creating lasting bonds."
        />
      </Helmet>
      <section className="about-bnr">
        <Container>
          <Row>
            <Col lg={4} md={12} className="about-bnr-left-col"></Col>
            <Col lg={8} md={12} className="about-bnr-right-col">
              <h4 data-aos="fade-up" data-aos-duration="500">
                Your navigators in a
              </h4>
              <h2 data-aos="fade-up" data-aos-duration="1000">
                sea of possibilities
              </h2>
              {/* <p data-aos="fade-up" data-aos-duration="1500">In this vast ocean of possibilities, we navigate alongside you, charting a course towards shores where true hearts meet.</p> */}
              <p data-aos="fade-up" data-aos-duration="1500">
              In a world where increasingly more of us feel isolated, the need to build a global community has never been more pronounced. An ecosystem where we can share experiences, learn from each other, lean in, and embrace the magic of diversity, reminding us that despite our surface differences, we share common challenges, dreams, aspirations, and a deep-rooted desire to belong.
              </p>
              <p data-aos="fade-up" data-aos-duration="1500">
              With this intent, we bring both online and offline experiences to you. 
              </p>
              <p data-aos="fade-up" data-aos-duration="1500">
              Our online space brings people together for virtual speed dating, interactive workshops, vibrant discussions, webinars, and global interest-based groups.  
              </p>
              <p data-aos="fade-up" data-aos-duration="1500">
              For those who prefer face-to-face interactions, we offer a wide range of offline activities, such as exotic travel, local interest-based gatherings, curated retreats, and theme-based mixers. 
              </p>
              <p data-aos="fade-up" data-aos-duration="1500">
              Find love, friendship, or even a shared sense of purpose, and remember that no matter where we are, we are all a part of something bigger than ourselves, capable of forming relationships that transcend borders, cultures and perhaps even our expectations.
              </p> 
            </Col>
          </Row>
        </Container>
        <BookConsultation />
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <div
                className="about-head"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <h2>
                  Every heart has a <span>story</span>, every soul a{" "}
                  <span>destination</span>.
                </h2>
              </div>
              <div className="about-info">
                <p data-aos="fade-up" data-aos-duration="1000">
                Whether your journey has weathered storms of change, experienced the gentle ebb and flow of time, or stands poised for its first exhilarating voyage, you’ll find encouragement, inspiration, and shared experiences in our diverse group. 
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <div className="about-story-col-left">
                <img
                  src={LadyDancing}
                  alt="Tow To Tango"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  ref={parallax0.ref}
                />
              </div>
            </Col>
            <Col lg={6}>
              <div
                className="about-story-col-right"
                data-aos="zoom-in"
                data-aos-duration="1000"
              >
                <div className="about-story-col-right-inner">
                  <p>Our interest groups connect singles with others who share their passions for personal growth, healing, adventure, culture, personal finance, dining, wine, books and podcasts, luxury, and off-the-beaten-path experiences.</p>
                </div>
                <div className="about-story-col-right-inner">
                  <p>Our community is united by curiosity, a thirst for adventure, and a zest for life. Our members are successful, well-travelled, and appreciate the finer things in life, but that's just the beginning.</p>
                </div> 
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="lets-show-section">
        <Container>
          {/* <Row>
                    <Col>
                        <div className='about-head' data-aos="zoom-in" data-aos-duration="1000">
                            <h2> 
                             <span>how its done</span> 
                            </h2>
                        </div>
                    </Col>
                </Row> */}
          {/* <Row>
                    <Col lg={4} md={4} sm={4}>
                        <div className='lets-show'>
                            <img src={LetsShowImg1} alt="Tow To Tango" data-aos="zoom-in" data-aos-duration="1000" />
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={4}>
                        <div className='lets-show'>
                            <img src={LetsShowImg2} alt="Tow To Tango" data-aos="zoom-in" data-aos-duration="1000" />
                        </div>
                    </Col>
                    <Col lg={4} md={4} sm={4}>
                        <div className='lets-show'>
                            <img src={LetsShowImg3} alt="Tow To Tango" data-aos="zoom-in" data-aos-duration="1000" />
                        </div>
                    </Col>
                </Row> */}
          <Row className="lets-show-3col">
            <Col lg={4}>
              <div className="why-3col">
                <div className="why-3col-img">
                  <img
                    src={LetsShowImg1}
                    alt="Tow To Tango"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  />
                </div>
                <div className="why-3col-text">
                  <div className="why-3col-text-info1">
                    <h6 data-aos="fade-up" data-aos-duration="500"> 
                      Find Your Tribe:  
                    </h6>
                    <p data-aos="fade-up" data-aos-duration="1000">
                    Meet people who share your passions and interests.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="why-3col">
                <div className="why-3col-img">
                  <img
                    src={LetsShowImg2}
                    alt="Tow To Tango"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  />
                </div>
                <div className="why-3col-text">
                  <div className="why-3col-text-info1">
                    <h6 data-aos="fade-up" data-aos-duration="500">
                    Curated Events:
                    </h6>
                    <p data-aos="fade-up" data-aos-duration="1000">
                    Join online and in-person events crafted for authentic conversations and true connections.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={4}>
              <div className="why-3col">
                <div className="why-3col-img">
                  <img
                    src={LetsShowImg3}
                    alt="Tow To Tango"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  />
                </div>
                <div className="why-3col-text">
                  <div className="why-3col-text-info1">
                    <h6 data-aos="fade-up" data-aos-duration="500">
                    Experience Belonging:
                    </h6>
                    <p data-aos="fade-up" data-aos-duration="1000">
                    Join a space rooted in warmth, authenticity, and genuine support.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="lets-show-para">
              <Link to="/join" className="joc">Join Our Community</Link>
                {/* <p data-aos="fade-up" data-aos-duration="1000">Our curated search eliminates the noise. Gone are the incompatible connections, the endless first dates that lead nowhere, and the annoying aunties that ask you to compromise!</p>
                            <p data-aos="fade-up" data-aos-duration="1500">Instead, you encounter individuals aligned with your vision, sparking conversations that resonate on a deeper level.</p> */}
                {/* <ListGroup  data-aos="fade-up" data-aos-duration="1000">
                                <ListGroup.Item
                                    data-aos="fade-up"
                                    data-aos-duration="500"
                                >Authenticity and Intent — Our members are looking for that meaningful connection; therefore, honesty and transparency take centre stage.</ListGroup.Item>
                                <ListGroup.Item
                                    data-aos="fade-up"
                                    data-aos-duration="1000"
                                >Quality over Quantity — Our members are not looking for numbers, but the right one. The curation and personalisation increase the chances of finding the one.</ListGroup.Item>
                                <ListGroup.Item
                                    data-aos="fade-up"
                                    data-aos-duration="1500"
                                >Enhanced Experience — Our members are successful, and time is their greatest luxury. They value our hand holding immensely, so that they can make an informed decision about whether to engage further.</ListGroup.Item> 
                            </ListGroup>   */}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            <Col>
              <div className="about-btm">
                <img
                  src={OldMusicSystem}
                  alt="Tow To Tango"
                  data-aos="fade-in-up"
                  data-aos-duration="1000"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
