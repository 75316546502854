import React, { useEffect, useState, useRef } from "react";
import "./Membership.css";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {
  Button,
  Col,
  Container,
  Dropdown,
  FloatingLabel,
  Form,
  NavLink,
  Row,
} from "react-bootstrap";

import BookConsultation from "../../Components/BookConsultation/BookConsultation";
import { useNavigate } from "react-router-dom";
import CoupleDancing from "../../images/couple-dancing.png";
import MembershipLightThumb from "../../images/mambership-light-thumb.jpg";
import MembershipLightCoupleThumb from "../../images/mambership-light-couple-thumb.jpg";
// import MembershipPackagesDancingCouple1 from "../../images/membership-packages-dancing-couple1.png";
// import MembershipPackagesDancingCouple2 from "../../images/membership-packages-dancing-couple2.png";
// import PlusMembership3colImg1 from "../../images/concierge-service-3col1.jpg";
// import PlusMembership3colImg2 from "../../images/concierge-service-3col2.jpg";
// import PlusMembership3colImg3 from "../../images/concierge-service-3col3.jpg";
import MembershipEmbraceImg from "../../images/membership-packages-form-side.png";
import { useParallax } from "react-scroll-parallax";
import { EmailExists, GetSignUpQ, SignupUser } from "../../apis/UserAPI";
import { useMediaQuery } from "react-responsive";
import Loader from "../../Components/Spinner/Loader";
import {
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import { GetCities } from "../../apis/MiscAPI";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector } from "react-redux";
import MonthlyMembership from "./MonthlyMembership";
import SixMonthsMembership from "./SixMonthsMembership";
import OneYearMembership from "./OneYearMembership";
import { Helmet } from "react-helmet-async";

export default function Membership() {
  const navigate = useNavigate();
  const { location } = useSelector((state) => state.auth);
  const [validated, setValidated] = useState(false);
  const [isEmailInvalid, setIsEmailInvalid] = useState(false);
  const [invalidEmailMsg, setInvalidEmailMsg] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [maxDate, setMaxDate] = useState("");
  const [minDate, setMinDate] = useState("");
  const [country, setCountry] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [formLoaded, setFormLoaded] = useState(false);

  const validationRegex = {
    firstName: /^[a-zA-Z' -]{2,30}$/,
    lastName: /^[a-zA-Z' -]{2,}$/,
    email: /^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?$/,
    age: /^(1[8-9]|[2-6][0-9]|70)$/,
  };

  const [signUpData, setSignUpData] = useState({
    firstName: "",
    lastName: "",
    emailId: "",
    phoneNo: "",
    dob: "",
    age: "",
    location: "",
    country: "",
    city: "",
    gender: "",
    qna: [],
    captcha: "",
    membershipId: 0,
  });

  const [phoneValidated, setPhoneValidated] = useState(true);
  const [dobValidated, setDobValidated] = useState(true);

  const updateSignUpData = (key, value) => {
    if (key === "dob") {
      value = ConvertDate(value);
      calculateAge(value);
      setDobValidated(true);
    }
    setSignUpData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const ConvertDate = (value) => {
    return new Date(
      Date.UTC(
        value.getFullYear(),
        value.getMonth(),
        value.getDate(),
        value.getHours(),
        value.getMinutes(),
        value.getSeconds()
      )
    );
  };

  const updateQnA = (qn, ans, render) => {
    var updatedUserQnA = signUpData.qna;

    const existingQIndex = updatedUserQnA.findIndex((q) => q.id === qn.id);

    if (existingQIndex !== -1) {
      // Update existing question
      const isInitialRender = render === "initial";
      qn.answer = isInitialRender ? qn.answer : ans;
      updatedUserQnA[existingQIndex] = qn;
    } else {
      // Add new question
      updatedUserQnA.push(qn);
    }
    updateSignUpData("qna", updatedUserQnA);
  };

  function formValid() {
    //console.log(signUpData);
    for (const key in signUpData) {
      // Skip validation for membershipId
      if (key !== "membershipId") {
        // Handle specific validations based on key
        switch (key) {
          case "qna":
            // Validate array of Q&A pairs
            const qna = signUpData[key];
            for (const qa of qna) {
              if (qa.question !== "Social Media link" && !qa.answer) {
                return false; // Return false if any answer is missing
              }
            }
            break;
          case "emailId":
            // Validate email using regex
            if (
              !validationRegex.email.test(signUpData[key]) ||
              isEmailInvalid
            ) {
              return false; // Return false if email is invalid
            }
            break;
          case "phoneNo":
            // Validate phone number
            if (
              signUpData[key] === "" ||
              isValidPhoneNumber(signUpData[key], countryCode) !== true
            ) {
              handlePhoneValidation();
              return false; // Return false if phone number is invalid
            }
            break;
          case "dob":
            // Validate date of birth
            if (!signUpData[key]) {
              setDobValidated(false); // Set date of birth validation status
              return false; // Return false if date of birth is missing
            } else {
              setDobValidated(true); // Set date of birth validation status
            }
            break;
          case "firstName":
            // Validate first name using regex
            if (!validationRegex.firstName.test(signUpData[key])) {
              return false; // Return false if first name is invalid
            }
            break;
          case "lastName":
            // Validate last name using regex
            if (!validationRegex.lastName.test(signUpData[key])) {
              return false; // Return false if last name is invalid
            }
            break;
          case "age":
            // Validate age using regex
            if (!validationRegex.age.test(signUpData[key])) {
              return false; // Return false if age is invalid
            }
            break;
          default:
            // Validate for any other required fields
            if (!signUpData[key]) {
              return false; // Return false if any other required field is missing
            }
        }
      }
    }

    // If all validations pass, return true
    return true;
  }

  const validateEmail = async () => {
    let emailid = signUpData.emailId;
    if (validationRegex.email.test(emailid)) {
      let uid = await EmailExists(emailid);
      if (uid !== 0) {
        setIsEmailInvalid(true);
        setInvalidEmailMsg("Email already exists!");
      } else {
        setIsEmailInvalid(false);
        setInvalidEmailMsg("");
      }
    } else {
      setIsEmailInvalid(true);
      setInvalidEmailMsg("Invalid email format");
    }
  };

  useEffect(() => {
    calculateMaxDate();
  }, []);

  useEffect(() => {
    if (location != null) getCountry();
  }, [location]);

  useEffect(() => {
    const getQn = async () => {
      var result = await GetSignUpQ();
      if (result.length > 0) setFormLoaded(true);
      result.forEach((element) => {
        updateQnA(element, null, "initial");
      });
    };
    getQn();
    loadCaptchaEnginge(6, "#CDAC45", "white");
    if (country != "" && country != null && country != undefined) {
      getCities();
    }
  }, [country]);

  const getCountry = async () => {
    setCountry(location.country);
    setCountryCode(location.countryCode);
    updateSignUpData("location", location.city + ", " + location.country);
    updateSignUpData("country", location.countryCode);
  };

  const getCities = async () => {
    var result = await GetCities(countryCode);
    setCities(result);
  };

  const calculateMaxDate = () => {
    const today = new Date();
    var mxDate = new Date(
      today.getFullYear() - 18,
      today.getMonth(),
      today.getDate()
    );
    var mnDate = new Date(
      today.getFullYear() - 70,
      today.getMonth(),
      today.getDate()
    );
    setMaxDate(mxDate);
    setMinDate(mnDate);
  };

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    var age = today.getFullYear() - birthDate.getFullYear();

    // Adjust for birth month and day
    if (
      today.getMonth() < birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    updateSignUpData("age", age);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsInitialLoad(false);

    const form = event.currentTarget;
    let userCaptcha = document.getElementById("captcha").value;
    if (validateCaptcha(userCaptcha) === true && formValid()) {
      if (form.checkValidity() === false) {
        event.stopPropagation();
      } else {
        event.preventDefault();
        var qna = [];
        signUpData.qna.forEach((item) => {
          qna.push({ QuestionId: item.id, Answer: item.answer });
        });
        const userData = {
          firstName: signUpData.firstName,
          lastName: signUpData.lastName,
          dob: signUpData.dob,
          age: signUpData.age + "",
          gender: signUpData.gender,
          userEmail: signUpData.emailId,
          phoneNo: signUpData.phoneNo,
          location: signUpData.location,
          country: signUpData.country,
          city: signUpData.city,
          membershipId: 0,
          qnA: qna,
        };
        setShowLoader(true);
        var id = await SignupUser(userData);
        setShowLoader(false);
        // if (countryCode === "IN") {
        //   navigate("/ThankYou");
        // } else {
        navigate(`/payment?uid=${encodeURIComponent(id)}&type=new`);
        //}
      }
    } else {
      updateSignUpData("captcha", "");
    }

    setValidated(false);
  };

  const isMobile = useMediaQuery({ query: "(max-width: 991px)" });

  const parallax0 = useParallax({
    translateX: [-100, 10, "easeOutQuint"],
    translateY: [0, 0, "easeInQuint"],
    disabled: isMobile,
  });

  const handleCityChange = (selectedOptions) => {
    if (selectedOptions.length > 0) {
      const selectedCityName = selectedOptions[0].name;
      setSelectedCity(selectedOptions);
      updateSignUpData("city", selectedCityName);
    } else {
      setSelectedCity([]); // Clear selection if no option is selected
      updateSignUpData("city", ""); // Clear city in contactUsData
    }
  };

  const defaultMembership = {
    monthly: false,
    sixmonth: false,
    oneyear: false,
  };

  const [showMembership, setShowMembership] = useState(defaultMembership);
  const [showForm, setShowForm] = useState(false);
  const menuCollapse = useRef(null);

  const handleMembershipChange = (type) => {
    setShowMembership(defaultMembership);
    setShowMembership((prevState) => ({ ...prevState, [type]: true }));
    setShowForm(true);
    menuCollapse.current.click();
  };

  const handlePhoneValidation = () => {
    var vp = isValidPhoneNumber(signUpData.phoneNo, countryCode);
    if (!vp) setPhoneValidated(false);
    else setPhoneValidated(true);
  };

  return (
    <>
      <Helmet>
        <title>Membership | Nuanced Matchmaking & Real Connections</title>
        <meta
          name="description"
          content="Join Two 2 Tango's exclusive membership for meaningful connections built on shared values. Experience personalized matchmaking and enhanced social experiences."
        />
      </Helmet>
      <BookConsultation />
      <section className="membership-bnr">
        <Container>
          <Row>
            <Col lg={8} md={12} className="membership-bnr-right-col">
              <h5 data-aos="fade-up" data-aos-duration="500">
                Glide into the world of:
              </h5>
              <h4 data-aos="fade-up" data-aos-duration="1000">
                <span>Human Iintelligence</span>: Not algorithms, for nuanced
                matchmaking.
              </h4>
              <h4 data-aos="fade-up" data-aos-duration="1500">
                <span>Real Connections</span>: Built on shared values.
              </h4>
              <h4 data-aos="fade-up" data-aos-duration="2000">
                <span>Enhanced Experiences</span>: Where sparks fly and hearts
                connect.
              </h4>
              <div
                className="invest-text"
                data-aos="fade-up"
                data-aos-duration="2500"
              >
                <h3>
                  Invest in <span> Yourself</span>. Invest in <span>Love</span>.
                </h3>
              </div>
            </Col>
            <Col lg={4} md={12} className="membership-bnr-left-col">
              <img
                src={CoupleDancing}
                alt="Tow To Tango"
                data-aos="zoom-in-up"
                data-aos-duration="1000"
                ref={parallax0.ref}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="membership-first-fold">
        <Container>
          <Row>
            <Col lg={4} md={6} className="membership-first-fold-left-col">
              <div className="membership-first-fold-left-col-inner">
                <div className="membership-first-fold-left-col-inner-img">
                  <img
                    src={MembershipLightThumb}
                    alt="Tow To Tango"
                    data-aos="zoom-in-up"
                    data-aos-duration="1000"
                  />
                </div>
                <div className="membership-first-fold-left-col-inner-img">
                  <img
                    src={MembershipLightCoupleThumb}
                    alt="Tow To Tango"
                    data-aos="fade-up"
                    data-aos-duration="1000"
                  />
                </div>
              </div>
            </Col>
            <Col lg={8} md={6} className="membership-first-fold-right-col">
              <div className="membership-first-fold-right-col-inner">
                <div className="membership-first-fold-right-col-inner-text">
                  {/* <h6 data-aos="fade-up" data-aos-duration="500">
                    More than meets the eye.
                  </h6> */}
                  <p data-aos="fade-up" data-aos-duration="1000">
                    {/* <strong>
                      Driven, passionate, and ready for a genuine connection.
                    </strong>{" "} */}
                    The transformative power of love, the undeniable sparks of
                    that first encounter and the magic of chemistry felt only by
                    being deeply connected.
                  </p>
                  <p>Discover a love that fits you like a glove. </p>
                  <h5
                    className="membership-qoute-text"
                    data-aos="fade-up"
                    data-aos-duration="1500"
                  >
                    “The best relationships are the ones you never expected to
                    be in, the ones you didn’t know you needed, and the ones you
                    can't imagine living without &nbsp;” - Unknown
                  </h5>
                </div>
              </div>
              <div className="select-membership-dropdown">
                <Dropdown
                  className="select-membership-dropdown-box"
                  autoClose="true"
                  ref={menuCollapse}
                >
                  <Dropdown.Toggle id="select-membership-dropdown-menu">
                    Select Membership Plan{" "}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {countryCode !== "IN" ? (
                      <NavLink
                        className="dropdown-item"
                        id="MonthlyMembershipBtn"
                        onClick={() => handleMembershipChange("monthly")}
                      >
                        The Tango Monthly
                      </NavLink>
                    ) : (
                      ""
                    )}
                    <NavLink
                      className="dropdown-item"
                      id="SixMonthlMembershipBtn"
                      onClick={() => handleMembershipChange("sixmonth")}
                    >
                      The Tango: 6 months
                    </NavLink>
                    <NavLink
                      className="dropdown-item"
                      id="OneYearMembershipBtn"
                      onClick={() => handleMembershipChange("oneyear")}
                    >
                      The Tango: 12 months
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {showMembership.monthly && (
        <div className="monthly-membership" id="MonthlyMembership">
          <MonthlyMembership />
        </div>
      )}
      {showMembership.sixmonth && (
        <div className="six-months-membership" id="SixMonthsMembership">
          <SixMonthsMembership countryCode={countryCode} />
        </div>
      )}
      {showMembership.oneyear && (
        <div className="one-year-membership" id="OneYearMembership">
          <OneYearMembership countryCode={countryCode} />
        </div>
      )}

      <section
        id="MembershipForm"
        className="membership-embrace"
        style={{ display: showForm ? "" : "none" }}
      >
        <Container>
          <Row>
            <Col className="membership-embrace-head">
              <h2>Embrace the Tango</h2>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} className="membership-embrace-left-col">
              <img src={MembershipEmbraceImg} alt="Tow To Tango" />
            </Col>
            <Col lg={6} md={6} className="membership-embrace-right-col">
              <div className="membership-embrace-form">
                <div className="signup-form">
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                    autoComplete="off"
                  >
                    <Row className="mb-3">
                      <FloatingLabel
                        as={Col}
                        md="12"
                        controlId="FName"
                        label="First Name"
                      >
                        <Form.Control
                          required
                          type="text"
                          placeholder="First Name"
                          value={signUpData.firstName}
                          onChange={(e) =>
                            updateSignUpData("firstName", e.target.value)
                          }
                          isInvalid={
                            !isInitialLoad &&
                            !validationRegex.firstName.test(
                              signUpData.firstName
                            )
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid first name (minimum 2
                          characters, alphabetic characters only).
                        </Form.Control.Feedback>
                      </FloatingLabel>

                      <FloatingLabel
                        as={Col}
                        md="12"
                        controlId="LName"
                        label="Last Name"
                      >
                        <Form.Control
                          required
                          type="text"
                          placeholder="Last Name"
                          value={signUpData.lastName}
                          onChange={(e) =>
                            updateSignUpData("lastName", e.target.value)
                          }
                          isInvalid={
                            !isInitialLoad &&
                            !validationRegex.lastName.test(signUpData.lastName)
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a valid last name (minimum 2
                          characters, alphabetic characters only).
                        </Form.Control.Feedback>
                      </FloatingLabel>

                      <FloatingLabel
                        as={Col}
                        md="12"
                        controlId="EmailAddress"
                        label="Email Address"
                      >
                        <Form.Control
                          required
                          type="email"
                          placeholder="Email Address"
                          value={signUpData.emailId}
                          onBlur={validateEmail}
                          onChange={(e) =>
                            updateSignUpData("emailId", e.target.value)
                          }
                          isInvalid={isEmailInvalid}
                        />
                        <Form.Control.Feedback type="invalid">
                          {invalidEmailMsg}
                        </Form.Control.Feedback>
                      </FloatingLabel>

                      <FloatingLabel
                        as={Col}
                        md="12"
                        controlId="PhoneNumber"
                        label="Phone Number"
                      >
                        <PhoneInput
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry={countryCode}
                          placeholder="Enter phone number"
                          value={signUpData.phoneNo}
                          onChange={(e) => updateSignUpData("phoneNo", e)}
                          rules={{ required: true }}
                          onBlur={handlePhoneValidation}
                        />
                        {!isInitialLoad && !phoneValidated && (
                          <Form.Text className="text-danger">
                            Please provide valid phone number
                          </Form.Text>
                        )}
                      </FloatingLabel>
                      <FloatingLabel
                        as={Col}
                        md="12"
                        controlId="DateOBirth"
                        label="Date of Birth"
                      >
                        <DatePicker
                          dayPlaceholder="dd"
                          monthPlaceholder="mm"
                          yearPlaceholder="yyyy"
                          value={signUpData.dob}
                          onChange={(e) => updateSignUpData("dob", e)}
                          maxDate={maxDate}
                          minDate={minDate}
                          required
                          onInvalidChange={() => setDobValidated(false)}
                        />
                        {!isInitialLoad && !dobValidated && (
                          <Form.Text className="text-danger">
                            Please provide valid dob
                          </Form.Text>
                        )}
                      </FloatingLabel>
                      <FloatingLabel
                        as={Col}
                        md="12"
                        controlId="Age"
                        label="Age"
                        data-aos="fade-up"
                        data-aos-duration="2000"
                      >
                        <Form.Control
                          type="number"
                          placeholder="Age"
                          value={signUpData.age}
                          onChange={(e) =>
                            updateSignUpData("age", e.target.value)
                          }
                          min={18}
                          max={70}
                          isInvalid={
                            signUpData.age === "" && !isInitialLoad
                              ? true
                              : false
                          }
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide valid age
                        </Form.Control.Feedback>
                      </FloatingLabel>

                      <FloatingLabel
                        as={Col}
                        md="12"
                        controlId="Location"
                        label="Location"
                      >
                        <Form.Control
                          required
                          type="text"
                          placeholder="Location"
                          value={signUpData.location}
                          onChange={(e) =>
                            updateSignUpData("location", e.target.value)
                          }
                          isInvalid={signUpData.location == "" ? true : false}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide location
                        </Form.Control.Feedback>
                      </FloatingLabel>
                      <FloatingLabel
                        as={Col}
                        md="12"
                        controlId="City"
                        label="City"
                      >
                        <Typeahead
                          id="membership_city"
                          labelKey="name"
                          options={cities}
                          placeholder="city living in"
                          selected={selectedCity}
                          isInvalid={
                            (!selectedCity || selectedCity.length == 0) &&
                            !isInitialLoad
                              ? true
                              : false
                          }
                          onChange={handleCityChange}
                          required
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide name of the city you live in
                        </Form.Control.Feedback>
                      </FloatingLabel>
                      <Form.Group
                        as={Col}
                        md="12"
                        className="radios-btns"
                        controlId="Gender"
                      >
                        <Form.Label>I am a</Form.Label>
                        <div className="radios-btns-gender">
                          <Form.Check
                            inline
                            label="Man"
                            name="Gender"
                            type="radio"
                            id="Male"
                            value="Male"
                            checked={signUpData.gender === "Male"}
                            onChange={(e) =>
                              updateSignUpData("gender", e.target.value)
                            }
                          />
                          <Form.Check inline name="Gender" id="Female">
                            <Form.Check.Input
                              type="radio"
                              value="Female"
                              checked={signUpData.gender === "Female"}
                              onChange={(e) =>
                                updateSignUpData("gender", e.target.value)
                              }
                              isInvalid={
                                !isInitialLoad && signUpData.gender == ""
                              }
                            />
                            <Form.Check.Label>Woman</Form.Check.Label>
                            <Form.Control.Feedback type="invalid">
                              Please fill this field
                            </Form.Control.Feedback>
                          </Form.Check>
                        </div>
                      </Form.Group>
                      {signUpData.qna.length > 0 &&
                        signUpData.qna.map((ques) =>
                          ques.field_type === "radio_text" ? (
                            <Form.Group
                              as={Col}
                              md="12"
                              className="radios-btns"
                              controlId={ques.id}
                              data-aos="fade-up"
                              data-aos-duration="2500"
                              key={ques.id}
                            >
                              <Form.Label>{ques.question}</Form.Label>
                              <div className="radios-btns-gender">
                                {ques.option !== null &&
                                  ques.option.split("~").map((opt, indx) => (
                                    <div key={`${ques.id}${indx}`}>
                                      {ques.option.split("~").length - 1 ===
                                      indx ? (
                                        <Form.Check
                                          inline
                                          name={`qo${indx}${ques.id}`}
                                          id={`${indx}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="radio"
                                            value={opt}
                                            checked={
                                              (ques.answer != null &&
                                                ques.answer == opt) ||
                                              (ques.answer != null &&
                                                ques.answer.indexOf("^") !==
                                                  -1 &&
                                                ques.answer.indexOf(
                                                  opt.trim()
                                                ) !== -1)
                                            }
                                            onChange={(e) =>
                                              updateQnA(
                                                ques,
                                                e.target.value,
                                                null
                                              )
                                            }
                                            isInvalid={
                                              !isInitialLoad &&
                                              ques.answer === ""
                                            }
                                          />
                                          <Form.Check.Label>
                                            {opt}
                                          </Form.Check.Label>
                                          <Form.Control.Feedback type="invalid">
                                            Please fill this field
                                          </Form.Control.Feedback>
                                        </Form.Check>
                                      ) : (
                                        <Form.Check
                                          inline
                                          name={`qo${indx}${ques.id}`}
                                          id={`${indx}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="radio"
                                            value={opt}
                                            checked={
                                              (ques.answer != null &&
                                                ques.answer == opt) ||
                                              (ques.answer != null &&
                                                ques.answer.indexOf("^") !==
                                                  -1 &&
                                                ques.answer.indexOf(
                                                  opt.trim()
                                                ) !== -1)
                                            }
                                            onChange={(e) =>
                                              updateQnA(
                                                ques,
                                                e.target.value,
                                                null
                                              )
                                            }
                                          />
                                          <Form.Check.Label>
                                            {opt}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      )}
                                      {opt.trim() === "Other" &&
                                        ques.answer != null &&
                                        ques.answer.indexOf("Other") !== -1 && (
                                          <>
                                            <Form.Control
                                              key={`QT_${indx}_${ques.id}`}
                                              type="text"
                                              placeholder={ques.question}
                                              value={
                                                ques.answer == null
                                                  ? ""
                                                  : ques.answer.split("^")[1]
                                              }
                                              onChange={(e) =>
                                                updateQnA(
                                                  ques,
                                                  "Other^" + e.target.value,
                                                  null
                                                )
                                              }
                                            />
                                          </>
                                        )}
                                    </div>
                                  ))}
                              </div>
                            </Form.Group>
                          ) : ques.field_type === "radio" ? (
                            <Form.Group
                              as={Col}
                              md="12"
                              className="radios-btns"
                              controlId={ques.id}
                              data-aos="fade-up"
                              data-aos-duration="2500"
                              key={ques.id}
                            >
                              <Form.Label>{ques.question}</Form.Label>
                              <div className="radios-btns-gender">
                                {ques.option !== null &&
                                  ques.option.split("~").map((o, i) => (
                                    <div key={`${ques.id}${i}`}>
                                      {ques.option.split("~").length - 1 ===
                                      i ? (
                                        <Form.Check
                                          inline
                                          name={`qo${i}${ques.id}`}
                                          id={`${i}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="radio"
                                            value={o}
                                            checked={
                                              ques.answer != null &&
                                              ques.answer == o
                                            }
                                            onChange={(e) =>
                                              updateQnA(ques, e.target.value)
                                            }
                                            isInvalid={
                                              !isInitialLoad &&
                                              ques.answer === ""
                                            }
                                          />
                                          <Form.Check.Label>
                                            {o}
                                          </Form.Check.Label>
                                          <Form.Control.Feedback type="invalid">
                                            Please fill this field
                                          </Form.Control.Feedback>
                                        </Form.Check>
                                      ) : (
                                        <Form.Check
                                          inline
                                          name={`qo${i}${ques.id}`}
                                          id={`${i}${ques.id}`}
                                        >
                                          <Form.Check.Input
                                            type="radio"
                                            value={o}
                                            checked={
                                              ques.answer != null &&
                                              ques.answer == o
                                            }
                                            onChange={(e) =>
                                              updateQnA(ques, e.target.value)
                                            }
                                          />
                                          <Form.Check.Label>
                                            {o}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      )}
                                    </div>
                                  ))}
                              </div>
                            </Form.Group>
                          ) : ques.field_type === "text" ? (
                            <FloatingLabel
                              as={Col}
                              md="12"
                              controlId={`Q_${ques.id}`}
                              label={ques.question}
                              data-aos="fade-up"
                              data-aos-duration="2000"
                              key={ques.id}
                            >
                              <Form.Control
                                type="text"
                                placeholder={ques.question}
                                value={ques.answer ?? ""}
                                onChange={(e) =>
                                  updateQnA(ques, e.target.value, null)
                                }
                              />
                            </FloatingLabel>
                          ) : (
                            ""
                          )
                        )}
                      <Form.Group
                        as={Col}
                        className="my-membership-inout-row"
                        controlId="captcha"
                        data-aos="fade-up"
                        data-aos-duration="1500"
                      >
                        <Form.Label column sm="12">
                          <LoadCanvasTemplateNoReload />
                        </Form.Label>
                        <Col sm="12">
                          <Form.Control
                            type="text"
                            placeholder="captcha"
                            value={signUpData.captcha}
                            onChange={(e) =>
                              updateSignUpData("captcha", e.target.value)
                            }
                            required
                          />
                        </Col>
                      </Form.Group>
                    </Row>
                    <Button
                      className="join-btn"
                      type="submit"
                      disabled={!formLoaded}
                    >
                      JOIN
                      <Loader visible={showLoader} />
                    </Button>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
