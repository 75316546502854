import React, { useEffect, useRef, useState } from "react";
import "./MyProfile.css";
import "react-image-crop/dist/ReactCrop.css";
import {
  Accordion,
  Button,
  Carousel,
  Col,
  Container,
  Form,
  Modal,
  Row,
} from "react-bootstrap";

import PencilIcon from "../../images/pencil-icon.png";
import CancelIcon from "../../images/cancel.png";
import LifeStyle from "./LifeStyle";
import Upbringing from "./Upbringing";
import Profession from "./Profession";
import Communication from "./Communication";
import Compatibility from "./Compatibility";
import Partner from "./Partner";
import AboutMe from "./AboutMe";
import Snapshot from "./Snapshot";
import { useDispatch, useSelector } from "react-redux";
import {
  DeletePic,
  GetUserPhoto,
  RemoveProfilePic,
  UpdateProfilePic,
  UploadUserPhoto,
} from "../../apis/PhotoAPI";
import { GetBucketUrl } from "../../utils/Utility";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { useDebounceEffect } from "./useDebounceEffect.ts";
import { canvasPreview } from "./CanvasPreview.ts";
import imageCompression from "browser-image-compression";
import Loader from "../../Components/Spinner/Loader.jsx";
import { updateProfileImg } from "../../slices/authSlice.jsx";
import { AlertMessage } from "../../Components/AlertMessage/AlertMessage.jsx";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AddFeedback, GetUserFeedback } from "../../apis/MyMatchesAPI.js";
import { GetUserSectionStatus } from "../../apis/ProfileAPI.js";
import { confirm } from "../../Components/ConfirmMessage/Confirmation.js";

export default function MyProfile() {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [searchParam] = useSearchParams();
  const userId = searchParam.get("userid");
  const matchType = searchParam.get("type");
  const [userName, setUserName] = useState("");
  const [profileId, setProfileId] = useState(0);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showUpload, setShowUpload] = useState(false);
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const imgRef = useRef();
  const previewCanvasRef = useRef();
  const previewThumbCanvasRef = useRef();
  const [showLoader, setShowLoader] = useState({
    upload: false,
    upload_profile: false,
    profile: false,
    delete: false,
    remove_profile: false,
  });
  const [photoId, setPhotoId] = useState(0);
  const [disableUploadBtn, setDisableUploadBtn] = useState(true);
  const [showMsg, setShowMsg] = useState(false);
  const handleMsgClose = () => setShowMsg(false);
  const [msgBody, setMsgBody] = useState("");

  const navigate = useNavigate();

  const [alertData, setAlertData] = useState({
    variant: "Dark",
    message: "Test",
    showAlert: false,
    date: Date.now(),
  });

  const defaultAutoSave = {
    aboutme: false,
    communication: false,
    compatibility: false,
    lifestyle: false,
    partner: false,
    profession: false,
    upbringing: false,
  };

  const [autoSave, setAutoSave] = useState(defaultAutoSave);

  const handleUploadShow = (id) => {
    setPhotoId(id);
    handleClose();
    setShowUpload(true);
  };
  const handleUploadClose = () => {
    setImgSrc("");
    setShowUpload(false);
    setCompletedCrop(null);
    previewCanvasRef.current = null;
    previewThumbCanvasRef.current = null;
  };
  const [userPhotos, setUserPhotos] = useState([]);
  const [profilePhoto, setProfilePhoto] = useState({});
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [imgSrc, setImgSrc] = useState("");
  const [aspect] = useState(1 / 0.99);
  const [showFeedback, setShowFeedback] = useState(false);
  const [feedbackSubmitBtnText, setFeedbackSubmitBtnText] = useState("Submit");
  const [disableFeedback, setDisableFeedback] = useState(false);
  const [snapshotData, setSnapshotData] = useState("");

  const [feedback, setFeedback] = useState({
    userId: userId,
    interest: "",
    feedback: "",
  });

  useEffect(() => {
    getPhotos();
    if (userId) getUserFeedback();
  }, [userId]);

  useEffect(() => {
    async function GetSectionStatus() {
      var result = await GetUserSectionStatus();
      var filledSection = result.filter((x) => x.section_status === 1).length;
      var notFilledSection = result.filter(
        (x) => x.section_status === 0
      ).length;
      var mBody = "";
      if (filledSection === 0)
        mBody = <p>Please fill all the sections and submit</p>;
      else {
        mBody = (
          <>
            <p>Please fill below incomplete sections and submit.</p>
            <ul>
              {result
                .filter((x) => x.section_status === 0)
                .map((nm) => (
                  <li>{nm.section_name}</li>
                ))}
            </ul>
          </>
        );
      }
      if (notFilledSection !== 0) {
        setMsgBody(mBody);
        setShowMsg(true);
      }
    }
    GetSectionStatus();
  }, []);

  const getUserFeedback = async () => {
    var result = await GetUserFeedback(userId);
    setFeedback({
      userId: userId,
      interest: result.response,
      feedback: matchType === "declined" ? "" : result.feedback,
    });

    if (
      result.response != null &&
      result.feedback != null &&
      matchType != "declined"
    )
      setDisableFeedback(true);
  };

  const handleUserName = (nm, id) => {
    setUserName(nm);
    setProfileId(id);
  };

  const getPhotos = async () => {
    var result = await GetUserPhoto(userId ?? 0);
    setUserPhotos(result);
    var pf = result.filter((x) => x.photo_isdefault === 1)[0];
    if (pf !== undefined) {
      setProfilePhoto(pf);
      if (!userId) {
        dispatch(
          updateProfileImg({
            profileImg: pf.photo_thumb,
            modifiedDate: pf.modified_date,
          })
        );
      }
    } else {
      setProfilePhoto("");
      if (!userId) {
        dispatch(
          updateProfileImg({
            profileImg: "",
            modifiedDate: "",
          })
        );
      }
    }
  };

  function onSelectFile(e) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setImgSrc(reader.result?.toString() || "");
        setDisableUploadBtn(false);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current &&
        previewThumbCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          previewThumbCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );

  function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(
      makeAspectCrop(
        {
          unit: "%",
          width: 90,
        },
        aspect,
        mediaWidth,
        mediaHeight
      ),
      mediaWidth,
      mediaHeight
    );
  }

  const handleImageUpload = async (e, isDefaultImg) => {
    e.preventDefault();
    setDisableUploadBtn(true);
    setShowLoader((prevState) => ({
      ...prevState,
      upload: isDefaultImg == 1 ? false : true,
      upload_profile: isDefaultImg == 1 ? true : false,
    }));
    const image = imgRef.current;
    const previewCanvas = previewCanvasRef.current;
    const previewThumbCanvas = previewThumbCanvasRef.current;

    if (!image || !previewCanvas || !previewThumbCanvas || !completedCrop) {
      throw new Error("Crop canvas does not exist");
    }

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY
    );

    const offthumbscreen = new OffscreenCanvas(150, 150);

    const ctx = offscreen.getContext("2d");
    const tctx = offthumbscreen.getContext("2d");
    if (!ctx && !tctx) {
      throw new Error("No 2d context");
    }

    ctx.drawImage(
      previewCanvas,
      0,
      0,
      previewCanvas.width,
      previewCanvas.height,
      0,
      0,
      offscreen.width,
      offscreen.height
    );

    tctx.drawImage(
      previewThumbCanvas,
      0,
      0,
      previewThumbCanvas.width,
      previewThumbCanvas.height,
      0,
      0,
      offthumbscreen.width,
      offthumbscreen.height
    );

    const blob = await offscreen.convertToBlob({
      type: "image/jpeg",
      quality: 1,
    });

    const thumbblob = await offthumbscreen.convertToBlob({
      type: "image/jpeg",
      quality: 1,
    });

    var croppedImage = await CompressOriginalImage(blob);
    var thumbImage = await ConvertToBase64("thumb", thumbblob);
    var orgname = "";
    var thmbname = "";
    var pimg = isDefaultImg;

    if (photoId !== 0) {
      var photo = userPhotos.filter((x) => x.photo_id === photoId)[0];
      orgname = photo.photo_big;
      thmbname = photo.photo_thumb;
      pimg = isDefaultImg == 1 ? isDefaultImg : photo.photo_isdefault;
    }

    const data = {
      id: photoId,
      originalImageName: orgname,
      originalImage: croppedImage,
      thumbImageName: thmbname,
      thumbImage: thumbImage,
      isProfileImage: pimg,
    };

    await UploadUserPhoto(data);
    await getPhotos();
    setShowLoader((prevState) => ({
      ...prevState,
      upload: false,
      upload_profile: false,
    }));
    handleUploadClose();
    setDisableUploadBtn(false);
  };

  async function ConvertToBase64(type, blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        if (reader.readyState === FileReader.DONE) {
          const base64data = reader.result;
          resolve(base64data);
        } else {
          reject(new Error("Error reading file"));
        }
      };

      reader.readAsDataURL(blob);
    });
  }

  async function CompressOriginalImage(croppedImg) {
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedImage = await imageCompression(croppedImg, options);
      return await ConvertToBase64("cropped", compressedImage);
    } catch (error) {
      console.log(error);
    }
  }

  const handleProfilePhoto = async (id) => {
    setShowLoader((prevState) => ({ ...prevState, profile: true }));
    await UpdateProfilePic(id);
    await getPhotos();
    setShowLoader((prevState) => ({ ...prevState, profile: false }));
  };

  const handleRemoveProfilePhoto = async () => {
    setShowLoader((prevState) => ({ ...prevState, remove_profile: true }));
    await RemoveProfilePic();
    await getPhotos();
    setShowLoader((prevState) => ({ ...prevState, remove_profile: false }));
  };

  const handleDeletePhoto = async (id) => {
    setShowLoader((prevState) => ({ ...prevState, delete: true }));
    await DeletePic(id);
    await getPhotos();
    setShowLoader((prevState) => ({ ...prevState, delete: false }));
    handleClose();
  };

  const handleTabAction = (e) => {
    var elem = e.target;
    var tab = elem.getAttribute("data-id");
    var parent = elem.closest(".accordion-collapse");
    if (elem.classList.contains("view")) {
      enableViewMode(parent);
      setAutoSave((prevState) => ({ ...prevState, [tab]: false }));
    }
    if (elem.classList.contains("edit")) {
      enableEditMode(parent);
      setAutoSave((prevState) => ({ ...prevState, [tab]: true }));
    }
  };

  const handleTabSelected = (e) => {
    enableViewMode(e);
    setAutoSave(defaultAutoSave);
    //window.scrollTo("0, 0");
    const section = document.getElementById("MyProfileInfo");
    section.scrollIntoView({ behavior: "smooth" });
  };

  const handleSnapShot = () => {
    setSnapshotData(new Date());
  };

  const handleTabClosed = (e) => {
    enableViewMode(e);
  };

  function enableViewMode(e) {
    var vbtn = e.querySelector(".view");
    var ebtn = e.querySelector(".edit");

    if (!userId) {
      vbtn.classList.remove("active");
      ebtn.classList.remove("active");
      vbtn.classList.add("active");
    }

    var pdiv = e.querySelector(".my-profile-info");
    pdiv.classList.remove("edit-mode");

    var inpts = e.querySelectorAll("input");
    var ta = e.querySelectorAll("textarea");
    var sel = e.querySelectorAll("select:not(.PhoneInputCountrySelect)");
    var btns = e.querySelectorAll("button:not(.non-edit)");
    Array.from(inpts).forEach(function (e, i) {
      e.disabled = true;
    });
    Array.from(ta).forEach(function (e, i) {
      e.disabled = true;
    });
    Array.from(sel).forEach(function (e, i) {
      e.disabled = true;
    });
    Array.from(btns).forEach(function (e, i) {
      e.disabled = true;
    });

    if (!userId) {
      setAlertData((prevState) => ({
        ...prevState,
        message: "View mode enabled",
        showAlert: true,
        date: Date.now(),
      }));
    }
  }

  function enableEditMode(e) {
    var vbtn = e.querySelector(".view");
    var ebtn = e.querySelector(".edit");

    vbtn.classList.remove("active");
    ebtn.classList.remove("active");
    ebtn.classList.add("active");

    var pdiv = e.querySelector(".my-profile-info");
    pdiv.classList.add("edit-mode");

    var inpts = e.querySelectorAll("input:not(.non-edit)");
    var ta = e.querySelectorAll("textarea:not(.non-edit)");
    var sel = e.querySelectorAll("select:not(.non-edit)");
    var btns = e.querySelectorAll("button:not(.non-edit)");
    Array.from(inpts).forEach(function (e, i) {
      e.disabled = false;
    });
    Array.from(ta).forEach(function (e, i) {
      e.disabled = false;
    });
    Array.from(sel).forEach(function (e, i) {
      e.disabled = false;
    });
    Array.from(btns).forEach(function (e, i) {
      e.disabled = false;
    });

    setAlertData((prevState) => ({
      ...prevState,
      message: "Edit mode enabled",
      showAlert: true,
      date: Date.now(),
    }));
  }

  const handleShowFeedback = () => {
    setShowFeedback(true);
  };

  const [enableFeedbackSubmit, setEnableFeedbackSubmit] = useState(false);
  const handleFeedbackUpdate = (p, v) => {
    setFeedback((prevState) => ({ ...prevState, [p]: v }));
  };

  useEffect(() => {
    if (feedback.interest != "" && feedback.feedback != "")
      setEnableFeedbackSubmit(true);
    else setEnableFeedbackSubmit(false);
  }, [feedback]);

  const handleFeedbackSubmit = async () => {
    setFeedbackSubmitBtnText("Submitting...");
    setEnableFeedbackSubmit(false);
    var data = {
      matchUserId: feedback.userId,
      hisResponse:
        user.userGender === "male"
          ? matchType == "declined"
            ? "Yes"
            : feedback.interest
          : "",
      herResponse:
        user.userGender === "female"
          ? matchType == "declined"
            ? "Yes"
            : feedback.interest
          : "",
      feedback: feedback.feedback,
    };

    await AddFeedback(data);
    setFeedbackSubmitBtnText("Submitted");

    setTimeout(() => {
      setFeedbackSubmitBtnText("Submit");
      setEnableFeedbackSubmit(true);
    }, 3000);
  };

  return (
    <>
      <section className="my-profile">
        <Container>
          <Row>
            <Col>
              <div className="my-profile-inner">
                <h2
                  className="healine"
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                >
                  <span>{userId ? userName : user.userName}</span>
                </h2>
                <div className="my-profile-inner-info">
                  <Row>
                    <Col lg={5} md={12}>
                      <div className="my-profile-pic-section">
                        <div
                          className="my-profile-pic-larg"
                          data-aos="zoom-in"
                          data-aos-duration="1000"
                        >
                          {userPhotos !== null &&
                          userPhotos.length > 0 &&
                          profilePhoto?.photo_big !== undefined ? (
                            userId ? (
                              <img
                                src={`${GetBucketUrl()}${profileId}/${
                                  profilePhoto.photo_big
                                }?modifed=${profilePhoto.modified_date}`}
                                alt="Two To Tango"
                              />
                            ) : (
                              <img
                                src={`${GetBucketUrl()}${user.userId}/${
                                  profilePhoto.photo_big
                                }?modifed=${profilePhoto.modified_date}`}
                                alt="Two To Tango"
                              />
                            )
                          ) : (
                            <img
                              src={`${GetBucketUrl()}${
                                user.userGender === "male"
                                  ? userId
                                    ? "default_woman.png"
                                    : "default_man.png"
                                  : userId
                                  ? "default_man.png"
                                  : "default_woman.png"
                              }`}
                              alt="Two To Tango"
                            />
                          )}
                        </div>
                        <div className="my-profile-pic-thumb">
                          {userPhotos.length > 0 &&
                            userPhotos.map((photo) => (
                              <div
                                key={photo.photo_id}
                                className="my-profile-pic-thumb-col"
                                data-aos="zoom-in"
                                data-aos-duration="1000"
                              >
                                {userId ? (
                                  <img
                                    src={`${GetBucketUrl()}${profileId}/${
                                      photo.photo_thumb
                                    }?modifed=${photo.modified_date}`}
                                    alt="Two To Tango"
                                  />
                                ) : (
                                  <img
                                    src={`${GetBucketUrl()}${user.userId}/${
                                      photo.photo_thumb
                                    }?modifed=${photo.modified_date}`}
                                    alt="Two To Tango"
                                  />
                                )}
                              </div>
                            ))}
                        </div>
                        <div
                          className={
                            userId
                              ? "my-profile-pic-view"
                              : "my-profile-pic-view"
                          }
                          data-aos="zoom-in"
                          data-aos-duration="1000"
                        >
                          {userId ? (
                            <>
                              <Button onClick={handleShow}>View Image</Button>{" "}
                              <Button onClick={handleShowFeedback}>
                                {disableFeedback
                                  ? "View Feedback"
                                  : matchType == "declined"
                                  ? "Rekindle Interest"
                                  : "Share Feedback"}
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button onClick={handleShow}>
                                <img src={PencilIcon} alt="Tow To Tango" /> Edit
                              </Button>
                              <Button
                                onClick={() => navigate("/uploaddocuments")}
                              >
                                Upload Document
                              </Button>
                            </>
                          )}

                          <Modal
                            className="edit-profile-modal"
                            show={show}
                            onHide={handleClose}
                            backdrop="static"
                            keyboard={false}
                            size="lg"
                            centered
                          >
                            <button
                              type="button"
                              className="btn-close"
                              onClick={handleClose}
                              aria-label="Close"
                            >
                              <img src={CancelIcon} alt="Tow To Tango" />
                            </button>
                            <Modal.Body>
                              <Carousel>
                                {userPhotos.length > 0 ? (
                                  userPhotos.map((photo) => (
                                    <Carousel.Item key={photo.photo_id}>
                                      {userId ? (
                                        <img
                                          src={`${GetBucketUrl()}${profileId}/${
                                            photo.photo_big
                                          }?modifed=${photo.modified_date}`}
                                          alt="Two To Tango"
                                        />
                                      ) : (
                                        <img
                                          src={`${GetBucketUrl()}${
                                            user.userId
                                          }/${photo.photo_big}?modifed=${
                                            photo.modified_date
                                          }`}
                                          alt="Two To Tango"
                                        />
                                      )}
                                      <Carousel.Caption>
                                        <div className="profile-edit-action">
                                          {!userId && userPhotos.length < 5 && (
                                            <Button
                                              type="button"
                                              onClick={() =>
                                                handleUploadShow(0)
                                              }
                                            >
                                              Upload Photo
                                            </Button>
                                          )}
                                          {!userId && (
                                            <Button
                                              type="button"
                                              onClick={() =>
                                                handleUploadShow(photo.photo_id)
                                              }
                                            >
                                              Replace Photo
                                            </Button>
                                          )}
                                          {!userId &&
                                            photo.photo_isdefault == 1 && (
                                              <Button
                                                type="button"
                                                onClick={() =>
                                                  handleRemoveProfilePhoto()
                                                }
                                              >
                                                Remove Profile Photo
                                                <Loader
                                                  visible={
                                                    showLoader.remove_profile
                                                  }
                                                />
                                              </Button>
                                            )}
                                          {!userId &&
                                            photo.photo_isdefault !== 1 && (
                                              <>
                                                <Button
                                                  type="button"
                                                  onClick={() =>
                                                    handleProfilePhoto(
                                                      photo.photo_id
                                                    )
                                                  }
                                                >
                                                  Set as profile pic
                                                  <Loader
                                                    visible={showLoader.profile}
                                                  />
                                                </Button>
                                                <Button
                                                  type="button"
                                                  onClick={() =>
                                                    handleDeletePhoto(
                                                      photo.photo_id
                                                    )
                                                  }
                                                >
                                                  Delete Photo
                                                  <Loader
                                                    visible={showLoader.delete}
                                                  />
                                                </Button>
                                              </>
                                            )}
                                        </div>
                                      </Carousel.Caption>
                                    </Carousel.Item>
                                  ))
                                ) : (
                                  <Carousel.Item>
                                    <img
                                      src={`${GetBucketUrl()}${
                                        user.userGender === "male"
                                          ? userId
                                            ? "default_woman.png"
                                            : "default_man.png"
                                          : userId
                                          ? "default_man.png"
                                          : "default_woman.png"
                                      }`}
                                      alt="Two To Tango"
                                    />
                                    <Carousel.Caption>
                                      {!userId && (
                                        <div className="profile-edit-action">
                                          <Button
                                            type="button"
                                            onClick={() => handleUploadShow(0)}
                                          >
                                            Upload Photo
                                          </Button>
                                        </div>
                                      )}
                                    </Carousel.Caption>
                                  </Carousel.Item>
                                )}
                              </Carousel>
                            </Modal.Body>
                          </Modal>
                          <Modal
                            show={showUpload}
                            onHide={handleUploadClose}
                            backdrop="static"
                            keyboard={false}
                            size="lg"
                            centered
                            className="edit-profile-image-uploader"
                          > 
                            <Modal.Header closeButton> </Modal.Header>
                            <Modal.Title>Upload Image</Modal.Title>
                            <Modal.Body>
                              <Form>
                                <Form.Group
                                  controlId="formFile"
                                  className="scale-crop-preview-box"
                                >
                                  <Form.Control
                                    type="file"
                                    accept="image/*"
                                    onChange={onSelectFile}
                                  />
                                  {!!imgSrc && (
                                    <>
                                      <div className="scale-crop">
                                        <ReactCrop
                                          crop={crop}
                                          onChange={(_, percentCrop) =>
                                            setCrop(percentCrop)
                                          }
                                          onComplete={(c) =>
                                            setCompletedCrop(c)
                                          }
                                          aspect={aspect}
                                          minWidth={400}
                                          minHeight={100}
                                          maxWidth={594}
                                          // circularCrop
                                        >
                                          <img
                                            ref={imgRef}
                                            alt="Crop me"
                                            src={imgSrc}
                                            style={{
                                              transform: `scale(${scale}) rotate(${rotate}deg)`,
                                            }}
                                            onLoad={onImageLoad}
                                          />
                                        </ReactCrop>

                                        <div className="scale-crop-action">
                                          <Form.Label>Scale</Form.Label>
                                          <Form.Range
                                            value={scale}
                                            min={1}
                                            max={5}
                                            step={0.1}
                                            onChange={(e) =>
                                              setScale(e.target.value)
                                            }
                                          />
                                          <Form.Label>Rotate</Form.Label>
                                          <Form.Range
                                            value={rotate}
                                            min={0}
                                            max={180}
                                            step={1}
                                            onChange={(e) =>
                                              setRotate(e.target.value)
                                            }
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  {!!completedCrop && (
                                    <div className="crop-preview">
                                      <canvas
                                        ref={previewCanvasRef}
                                        style={{
                                          border: "1px solid black",
                                          objectFit: "contain",
                                          width: completedCrop.width,
                                          height: completedCrop.height,
                                        }}
                                      />{" "}
                                      <canvas
                                        ref={previewThumbCanvasRef}
                                        style={{
                                          border: "1px solid black",
                                          objectFit: "contain",
                                          width: 150,
                                          height: 150,
                                        }}
                                      />
                                    </div>
                                  )}
                                </Form.Group>
                              </Form>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                type="button"
                                onClick={(e) => handleImageUpload(e, 1)}
                                disabled={disableUploadBtn}
                              >
                                Save & Set as Profile Pic
                                <Loader visible={showLoader.upload_profile} />
                              </Button>
                              <Button
                                type="button"
                                onClick={(e) => handleImageUpload(e, 0)}
                                disabled={disableUploadBtn}
                              >
                                Save
                                <Loader visible={showLoader.upload} />
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                      <div
                        className={
                          !showFeedback
                            ? "profile-feedback hide-feedback-box"
                            : "profile-feedback show-feedback-box"
                        }
                      >
                        <div
                          className="profile-feedback-box"
                          data-aos="fade-up"
                          data-aos-duration="1000"
                        >
                          <Form>
                            {matchType !== "declined" && (
                              <Form.Group
                                as={Col}
                                md="12"
                                className="radios-btns"
                                controlId="Response"
                              >
                                <Form.Label>
                                  Are you interested in this profile?
                                </Form.Label>
                                <div className="radios-btns-gender">
                                  <Form.Check
                                    inline
                                    label="Yes"
                                    name="Response"
                                    type="radio"
                                    id="Yes"
                                    value="Yes"
                                    checked={feedback.interest === "Yes"}
                                    disabled={disableFeedback}
                                    onChange={(e) =>
                                      handleFeedbackUpdate(
                                        "interest",
                                        e.target.value
                                      )
                                    }
                                  />
                                  <Form.Check
                                    inline
                                    label="No"
                                    name="Response"
                                    type="radio"
                                    id="No"
                                    value="No"
                                    checked={feedback.interest === "No"}
                                    disabled={disableFeedback}
                                    onChange={(e) =>
                                      handleFeedbackUpdate(
                                        "interest",
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </Form.Group>
                            )}
                            <Form.Group controlId="ShareFeedback">
                              <Form.Control
                                as="textarea"
                                className="white-placeholder"
                                value={feedback.feedback}
                                placeholder="Please share your feedback if any."
                                readOnly={disableFeedback}
                                onChange={(e) =>
                                  handleFeedbackUpdate(
                                    "feedback",
                                    e.target.value
                                  )
                                }
                              />
                            </Form.Group>
                          </Form>
                        </div>

                        {!disableFeedback ? (
                          <div
                            className="profile-feedback-btn"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                          >
                            <Button
                              type="button"
                              disabled={!enableFeedbackSubmit}
                              onClick={() => handleFeedbackSubmit()}
                            >
                              {feedbackSubmitBtnText}
                            </Button>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                    <Col lg={7} md={12}>
                      <div
                        className="my-profile-inner-info-inner"
                        id="MyProfileInfo"
                      >
                        <AlertMessage
                          variant={alertData.variant}
                          message={alertData.message}
                          show={alertData.showAlert}
                          delay={3000}
                          date={alertData.date}
                        />
                        <Accordion defaultActiveKey="0">
                          <Accordion.Item
                            eventKey="0"
                            data-aos="fade-up"
                            data-aos-duration="1000"
                          >
                            <Accordion.Header>About Me </Accordion.Header>
                            <Accordion.Body
                              onEntered={handleTabSelected}
                              onExited={handleTabClosed}
                            >
                              {!userId && (
                                <div className="action-div">
                                  <Button
                                    type="button"
                                    variant=""
                                    onClick={handleTabAction}
                                    className="view non-edit active"
                                    data-id="aboutme"
                                  >
                                    View
                                  </Button>
                                  <Button
                                    type="button"
                                    variant=""
                                    onClick={handleTabAction}
                                    className="edit non-edit"
                                    data-id="aboutme"
                                  >
                                    Edit
                                  </Button>
                                </div>
                              )}

                              <AboutMe
                                alertData={alertData}
                                setAlertData={setAlertData}
                                viewmodefunc={enableViewMode}
                                autoSaveMode={autoSave}
                                setProfileName={handleUserName}
                                userid={userId}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Lifestyle</Accordion.Header>
                            <Accordion.Body
                              onEntered={handleTabSelected}
                              onExited={handleTabClosed}
                            >
                              {!userId && (
                                <div className="action-div">
                                  <Button
                                    type="button"
                                    variant=""
                                    onClick={handleTabAction}
                                    className="view non-edit"
                                    data-id="lifestyle"
                                  >
                                    View
                                  </Button>
                                  <Button
                                    type="button"
                                    variant=""
                                    onClick={handleTabAction}
                                    className="edit non-edit"
                                    data-id="lifestyle"
                                  >
                                    Edit
                                  </Button>
                                </div>
                              )}

                              <LifeStyle
                                alertData={alertData}
                                setAlertData={setAlertData}
                                autoSaveMode={autoSave}
                                userid={userId}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              Family & Upbringing
                            </Accordion.Header>
                            <Accordion.Body
                              onEntered={handleTabSelected}
                              onExited={handleTabClosed}
                            >
                              {!userId && (
                                <div className="action-div">
                                  <Button
                                    type="button"
                                    variant=""
                                    onClick={handleTabAction}
                                    className="view non-edit"
                                    data-id="upbringing"
                                  >
                                    View
                                  </Button>
                                  <Button
                                    type="button"
                                    variant=""
                                    onClick={handleTabAction}
                                    className="edit non-edit"
                                    data-id="upbringing"
                                  >
                                    Edit
                                  </Button>
                                </div>
                              )}

                              <Upbringing
                                alertData={alertData}
                                setAlertData={setAlertData}
                                autoSaveMode={autoSave}
                                userid={userId}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              Your Future Partner
                            </Accordion.Header>
                            <Accordion.Body
                              onEntered={handleTabSelected}
                              onExited={handleTabClosed}
                            >
                              {!userId && (
                                <div className="action-div">
                                  <Button
                                    type="button"
                                    variant=""
                                    onClick={handleTabAction}
                                    className="view non-edit"
                                    data-id="partner"
                                  >
                                    View
                                  </Button>
                                  <Button
                                    type="button"
                                    variant=""
                                    onClick={handleTabAction}
                                    className="edit non-edit"
                                    data-id="partner"
                                  >
                                    Edit
                                  </Button>
                                </div>
                              )}

                              <Partner
                                alertData={alertData}
                                setAlertData={setAlertData}
                                autoSaveMode={autoSave}
                                userid={userId}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>
                              Education & Professional Life
                            </Accordion.Header>
                            <Accordion.Body
                              onEntered={handleTabSelected}
                              onExited={handleTabClosed}
                            >
                              {!userId && (
                                <div className="action-div">
                                  <Button
                                    type="button"
                                    variant=""
                                    onClick={handleTabAction}
                                    className="view non-edit"
                                    data-id="profession"
                                  >
                                    View
                                  </Button>
                                  <Button
                                    type="button"
                                    variant=""
                                    onClick={handleTabAction}
                                    className="edit non-edit"
                                    data-id="profession"
                                  >
                                    Edit
                                  </Button>
                                </div>
                              )}

                              <Profession
                                alertData={alertData}
                                setAlertData={setAlertData}
                                autoSaveMode={autoSave}
                                userid={userId}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="5">
                            <Accordion.Header>
                              Communication & Emotional Compatibility
                            </Accordion.Header>
                            <Accordion.Body
                              onEntered={handleTabSelected}
                              onExited={handleTabClosed}
                            >
                              {!userId && (
                                <div className="action-div">
                                  <Button
                                    type="button"
                                    variant=""
                                    onClick={handleTabAction}
                                    className="view non-edit"
                                    data-id="communication"
                                  >
                                    View
                                  </Button>
                                  <Button
                                    type="button"
                                    variant=""
                                    onClick={handleTabAction}
                                    className="edit non-edit"
                                    data-id="communication"
                                  >
                                    Edit
                                  </Button>
                                </div>
                              )}

                              <Communication
                                alertData={alertData}
                                setAlertData={setAlertData}
                                autoSaveMode={autoSave}
                                userid={userId}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="6">
                            <Accordion.Header>Compatibility</Accordion.Header>
                            <Accordion.Body
                              onEntered={handleTabSelected}
                              onExited={handleTabClosed}
                            >
                              {!userId && (
                                <div className="action-div">
                                  <Button
                                    type="button"
                                    variant=""
                                    onClick={handleTabAction}
                                    className="view non-edit"
                                    data-id="compatibility"
                                  >
                                    View
                                  </Button>
                                  <Button
                                    type="button"
                                    variant=""
                                    onClick={handleTabAction}
                                    className="edit non-edit"
                                    data-id="compatibility"
                                  >
                                    Edit
                                  </Button>
                                </div>
                              )}

                              <Compatibility
                                alertData={alertData}
                                setAlertData={setAlertData}
                                autoSaveMode={autoSave}
                                userid={userId}
                              />
                            </Accordion.Body>
                          </Accordion.Item>
                          {!userId && (
                            <Accordion.Item eventKey="7">
                              <Accordion.Header>
                                My Life In A Snapshot
                              </Accordion.Header>
                              <Accordion.Body 
                              onEntered={handleSnapShot}
                              >
                                <Snapshot snapshot={snapshotData} />
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                        </Accordion>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Modal
        show={showMsg}
        onHide={handleMsgClose}
        backdrop="static"
        keyboard={false}
        size="md"
        centered
        className="edit-profile-image-uploader"
      >
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Title>Section Completion Status</Modal.Title>  
        <Modal.Body className="black-text">{msgBody}</Modal.Body>
        <Modal.Footer>
          <Button type="button" onClick={handleMsgClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal> 
    </>
  );
}
