import React from 'react';
import './Notfound.css'; 
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

export default function Notfound() {
  return (
    <>
      <section className='not-found'>
        <Container>
          <Row>
            <Col>
              <h1>404</h1>
              <h2>Page Not Found</h2>
              <p><Link to="/">Back to Home</Link></p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
